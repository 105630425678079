import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { PersonIcon } from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  ORDER_DETAILS,
  MEMBERS_PATH,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  UNASSIGNED_MEMBERS,
  MEMBER_CHART_CALENDARING,
  NURSE_SCHEDULE,
  NURSE_SCHEDULES,
  STAFF_DETAILS,
  LOGOUT,
  START_INTAKE,
  PROVIDER_SCHEDULES_SIDEBAR,
  PROVIDER_SCHEDULES
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RolesEnum from "common/enums/RolesEnum";
import FeatureFlags from "common/config/FeatureFlags";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        {
          itemName: "My Intakes",
          itemLink: "/my-intakes",
          ItemIcon: PersonIcon
        },
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "Nurse Schedules",
          itemLink: "/nurse-schedules",
          ItemIcon: PersonIcon
        }
      ]
    }
  ];
  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [PROVIDER_SCHEDULES_SIDEBAR]
    });
  }
  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);
  return sidebarItems;
};

const NP_NURSE = {
  label: "Intake Nurse",
  routes: [
    {
      path: "/",
      components: [
        ALL_MEMBERS({
          tableColumns: [
            { name: "name" },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    {
      path: "/my-intakes",
      components: [
        {
          type: "intakes",
          componentParams: {
            externalLink: MEMBERS_PATH,
            componentHeader: "My Intake History",
            linked_entities: [MemberLinkedEntitiesEnum.NURSE]
          }
        }
      ]
    },
    {
      path: "/members",
      components: [
        ALL_MEMBERS({
          tableColumns: [
            { name: "name" },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(false)] },
    {
      path: "/unassigned-members",
      components: [UNASSIGNED_MEMBERS]
    },
    ...MEMBER_CHART(RolesEnum.NP_NURSE),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    START_INTAKE,
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    NURSE_SCHEDULES,
    NURSE_SCHEDULE,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    STAFF_DETAILS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    LOGOUT
  ],
  sidebar: sidebar()
};

export default NP_NURSE;
