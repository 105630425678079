import { Box, Button, styled, Typography } from "@mui/material";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useGetMemberWithUsernameQuery, useGetVisitsQuery } from "common/services/MemberService";
import FeatureFlags from "common/config/FeatureFlags";
import {
  getElapsedTime,
  safeRefetch,
  tickElapsedTime
} from "../pages/Visits/VisitHelper";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitLinkedEntitiesEnum from "common/enums/VisitLinkedEntitiesEnum";

const VisitContainer = styled(Box)`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 5px;
`;

const WhiteButton = styled(Button)`
  background-color: white;
`;

const VisitInProgress = ({ staffId }: { staffId: string }) => {
  const dispatch = useAppDispatch();
  const [lastRoute, setLastRoute] = useState<String>(null);
  if (!FeatureFlags(staffId).CARE_FLOWS) return;
  const { visitId } = useSelector((state: RootState) => state.visits);

  const navigate = useNavigate();

  const {
    data: visits,
    isLoading,
    refetch,
  } = useGetVisitsQuery(
    {
      staff_id: staffId,
      status: VisitStatusEnum.IN_PROGRESS,
      linked_entities: [VisitLinkedEntitiesEnum.CARE_FLOW_SUMMARY]
    },
    { skip: staffId === undefined }
  );

  const visit = useMemo(() => {
    if (isFalsy(visits)) return;

    return visits.find(
      (item) =>
        item.status === VisitStatusEnum.IN_PROGRESS && item.care_flow_summary
    );
  }, [visits]);

  let lastProcessedTime: DateTime;
  useEffect(() => {
    if (lastRoute === null || window.location.href !== lastRoute) {
      doRefetch();
      setLastRoute(window.location.href);
    }
    const interval = tickElapsedTime(dispatch, lastProcessedTime);

    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  const doRefetch = () => {
    // Only do this if not in the care flow
    const stillInCareFlow = window.location.href.includes(visitId);
    if (!stillInCareFlow) {
      safeRefetch(refetch);
    }
  };

  const { data: member } = useGetMemberWithUsernameQuery(
    { username: visit?.patient_id },
    { skip: visit === undefined }
  );

  const getTimeFormat = (dateTime: DateTime) => {
    if (dateTime.toUTC().hour === 0) return "mm:ss";
    else return "hh:mm:ss";
  };

  const handleResumeClick = () => {
    navigate(`/visits/${visit?.visit_id}`);
  };

  let elapsedStartedTotalDateTime: DateTime = getElapsedTime(visit);

  if (visit === undefined || isLoading)  return;

  return (
    <VisitContainer margin={"10px 2.5%"}>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        {elapsedStartedTotalDateTime && (
          <>
            <Typography variant="h6" color={"white"}>
              Timer
            </Typography>
            <Typography variant="body1" color={"white"}>
              {elapsedStartedTotalDateTime.toFormat(
                getTimeFormat(elapsedStartedTotalDateTime)
              )}
            </Typography>
          </>
        )}
      </Box>
      {member && (
        <Typography variant="h6" color={"white"}>
          Task in Progress for {getNameOrUsername(member?.patient)}
        </Typography>
      )}
      <WhiteButton onClick={handleResumeClick}>Resume</WhiteButton>
    </VisitContainer>
  );
};

export default VisitInProgress;
