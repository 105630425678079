import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import {
  useGetCalendarEventsQuery,
  useGetStaffRecurringEventsQuery
} from "common/services/CalendarService";
import { useGetVisitsQuery } from "common/services/VisitsService";
import RolesEnum, {
  canEditScheduleTypeOnProfileInfo
} from "common/enums/RolesEnum";
import {
  checkIdValid,
  getDispositionIndexInVisits,
  isFalsy
} from "common/helpers/helpers";
import CalendarRecurringEventInfoResponseType from "common/types/Calendaring/CalendarRecurringEventInfoResponseType";
import {
  isMemberAppointment,
  isNurseAppointment
} from "common/helpers/CalendarHelper";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";

interface CalendarVisits extends CalendarEventResponseType {
  visits?: GetVisitResponseType[];
}

const useGetCalendarVisits = ({
  staff_id,
  patient_id,
  startdate,
  enddate,
  sort = "asc",
  skip = false,
  currentRole
}: {
  staff_id?: string;
  patient_id?: string;
  startdate: DateTime;
  enddate: DateTime;
  currentRole: RolesEnum;
  sort?: "asc" | "desc";
  skip?: boolean;
}) => {
  const {
    data: calendarEventsData,
    isUninitialized: calendarEventsIsUninitialized,
    isSuccess: calendarEventsIsSuccess,
    isFetching: calendarEventsisFetching,
    error: calendarEventsError,
    refetch: refetchCalendarEvents
  } = useGetCalendarEventsQuery(
    {
      staff_id: staff_id,
      patient_id: patient_id,
      startdate: startdate,
      enddate: enddate
    },
    {
      // TBD remove when switching to appointments endpoint
      // this skip condition is used to prevent the query from running if the staff_id is not valid
      // we won't need this file anymore when the backend is updated to get staff schedules using the appointments endpoint
      // skip: isFalsy(staff_id) || !checkIdValid(staff_id) ||
      skip
    }
  );

  const {
    data: visitsData,
    isUninitialized: visitsDataIsUninitialized,
    isSuccess: visitsDataIsSuccess,
    isFetching: visitsDataisFetching,
    error: visitsError,
    refetch: refetchVisits
  } = useGetVisitsQuery(
    {
      staff_id: staff_id,
      patient_id: patient_id,
      calendar_event_start_after: startdate,
      calendar_event_end_before: enddate
    },
    {
      // TBD remove when switching to appointments endpoint
      // this skip condition is used to prevent the query from running if the staff_id is not valid
      // we won't need this file anymore when the backend is updated to get staff schedules using the appointments endpoint
      // skip: isFalsy(staff_id) || !checkIdValid(staff_id) ||
      skip
    }
  );

  const {
    data: staffRecurringEventsData,
    isUninitialized: staffRecurringEventsIsUninitialized,
    isSuccess: staffRecurringEventsIsSuccess,
    isFetching: staffRecurringEventsisFetching,
    error: staffRecurringEventsError,
    refetch: refetchStaffRecurringEvents
  } = useGetStaffRecurringEventsQuery(
    {
      staff_id: staff_id
    },
    {
      skip:
        skip ||
        !canEditScheduleTypeOnProfileInfo(currentRole) ||
        isFalsy(staff_id) ||
        !checkIdValid(staff_id)
    }
  );

  const [finalData, setFinalData] = useState<CalendarVisits[]>();
  const [recurringEventsWeekdaysMap, setRecurringEventsWeekdaysMap] = useState<
    Record<string, CalendarRecurringEventInfoResponseType[]>
  >({});
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const hasRecurringEventsData =
      canEditScheduleTypeOnProfileInfo(currentRole) && checkIdValid(staff_id)
        ? !isFalsy(staffRecurringEventsData)
        : true;
    if (
      visitsData &&
      calendarEventsData &&
      (hasRecurringEventsData || staffRecurringEventsIsSuccess)
    ) {
      setIsProcessing(true);
      const visitsMap = {};
      const recurringEventsMap = {};
      const recurringEventsMapWeekdaysMap = {
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: []
      };

      if (staffRecurringEventsData) {
        staffRecurringEventsData?.forEach((event) => {
          const recurring_event_id = event?.recurring_event_id;
          if (!recurringEventsMap?.[recurring_event_id]) {
            recurringEventsMap[recurring_event_id] = event;
          }

          if (
            event?.recurrence?.date_of_week &&
            // we want to hide INACTIVE recurring events from this list
            event?.status !== "INACTIVE"
          ) {
            recurringEventsMapWeekdaysMap?.[
              event?.recurrence?.date_of_week
            ]?.push(event);
          }
        });
        setRecurringEventsWeekdaysMap(recurringEventsMapWeekdaysMap);
      }

      visitsData.forEach((visit) => {
        const calendarId = visit.calendar_id;
        if (!visitsMap[calendarId]) {
          visitsMap[calendarId] = [visit];
        } else {
          visitsMap[calendarId].push(visit);
        }
      });

      // possible tbd - check for why calendar events are missing after calling /appointments/end as an NP
      // this depends on the backend
      // if we can use the /appointments/staff endpoint, then we can delete this file and not worry about it

      let mergedData = calendarEventsData
        .map((item) => {
          const visits = visitsMap?.[item?.event_id] || [];
          const recurrenceData = recurringEventsMap?.[item?.recurring_id] ?? {};

          return {
            ...item,
            recurrenceData,
            visits
          };
        })
        .sort((a, b) => {
          if (sort === "asc") {
            return a.startdate < b.startdate ? -1 : 1;
          } else {
            return a.startdate > b.startdate ? -1 : 1;
          }
        });

      setFinalData(mergedData);
      setIsProcessing(false);
    }
  }, [visitsData, calendarEventsData, staffRecurringEventsData, currentRole]);

  useEffect(() => {
    if (calendarEventsError || visitsError || staffRecurringEventsError) {
      setIsProcessing(false);
    }
  }, [calendarEventsError, visitsError, staffRecurringEventsError]);

  const isFetching = useMemo(() => {
    return (
      calendarEventsisFetching ||
      visitsDataisFetching ||
      staffRecurringEventsisFetching ||
      isProcessing
    );
  }, [
    calendarEventsisFetching,
    visitsDataisFetching,
    staffRecurringEventsisFetching,
    isProcessing
  ]);

  function refetch() {
    if (!calendarEventsIsUninitialized) {
      refetchCalendarEvents();
    }
    if (!visitsDataIsUninitialized) {
      refetchVisits();
    }

    if (!staffRecurringEventsIsUninitialized) {
      refetchStaffRecurringEvents();
    }

    if (calendarEventsIsUninitialized && visitsDataIsUninitialized) {
      setIsProcessing(false);
    }
  }

  return {
    refetch,
    data: finalData,
    recurringEventsWeekdaysMap,
    isSuccess:
      calendarEventsIsSuccess &&
      visitsDataIsSuccess &&
      (canEditScheduleTypeOnProfileInfo(currentRole) && checkIdValid(staff_id)
        ? staffRecurringEventsIsSuccess
        : true),
    isFetching,
    isError: calendarEventsError || visitsError,
    error: visitsError ?? calendarEventsError
  };
};

const filterUpcomingAppointments = ({
  data,
  startDate
}: {
  data: CalendarVisits[];
  startDate: DateTime;
}) => {
  const ptoArray = [];
  const filteredData = data?.filter((item) => {
    const hasCompletedVisit =
      item?.visits?.findIndex(
        (visit) => visit?.status === VisitStatusEnum.COMPLETED
      ) > -1;
    const hasCompletedDisposition =
      getDispositionIndexInVisits(
        item?.visits,
        VisitDispositionEnum.COMPLETED
      ) > -1;
    const hasNoShowDisposition =
      getDispositionIndexInVisits(item?.visits, VisitDispositionEnum.NO_SHOW) >
      -1;
    const hasTnOooDisposition =
      getDispositionIndexInVisits(item?.visits, VisitDispositionEnum.TN_OOO) >
      -1;
    const hasNoCallDisposition =
      getDispositionIndexInVisits(item?.visits, VisitDispositionEnum.NO_CALL) >
      -1;

    const start = DateTime.fromISO(item.startdate);
    // subtract an hour -  an appointment is upcoming if it is happening now
    const upcomingFilterStart = startDate.minus({ hours: 1 });

    const shouldbeFilteredOut =
      start.diff(upcomingFilterStart).milliseconds < 0;

    const end = DateTime.fromISO(item.enddate);
    const isMemberAppt = isMemberAppointment(item?.appointment_type);

    if (!isMemberAppt) {
      // add pto to an array
      ptoArray.push({ ...item, start, end });
    }

    return (
      !shouldbeFilteredOut &&
      // filter out non-member appointments
      isMemberAppt &&
      // is in the future
      end.diffNow().milliseconds >= 0 &&
      // and does not have a completed/no_show visit or disposition
      !(
        hasCompletedVisit ||
        hasCompletedDisposition ||
        hasNoShowDisposition ||
        hasTnOooDisposition ||
        hasNoCallDisposition
      )
    );
  });

  return filteredData?.filter((item) => {
    const itemStart = DateTime.fromISO(item.startdate);
    const itemEnd = DateTime.fromISO(item.enddate);
    for (let pto of ptoArray) {
      const ptoStart = pto.start;
      const ptoEnd = pto.end;

      const isStartInPtoTime =
        ptoStart.diff(itemStart).milliseconds < 0 &&
        ptoEnd.diff(itemStart).milliseconds > 0;
      const isEndInPtoTime =
        ptoStart.diff(itemEnd).milliseconds < 0 &&
        ptoEnd.diff(itemEnd).milliseconds > 0;

      if (isStartInPtoTime || isEndInPtoTime) {
        // hide event if it is in PTO time
        return false;
      }
    }
    return true;
  });
};

const filterTodaysUpcomingAppointment = ({ data }) => {
  return data?.filter((item) => {
    const itemStart = DateTime.fromISO(item.startdate);
    const isNurseAppt = isNurseAppointment(item?.appointment_type);
    return itemStart.hasSame(DateTime.local(), "day") && isNurseAppt;
  });
};

export default useGetCalendarVisits;

export { filterUpcomingAppointments, filterTodaysUpcomingAppointment };
