import { DateTime } from "luxon";
import { Column, DefaultTableCell } from "../../styling/StyleComponents";
import { store } from "common/redux";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import config from "../../routes";
import { Typography } from "@mui/material";

const dateCell = ({ getValue, format = "MM/dd/yyyy" }) => {
  return (
    <DefaultTableCell>
      {DateTime.fromSQL(getValue()).toFormat(format)}
    </DefaultTableCell>
  );
};

const dateWithRelative = ({ date }: { date: DateTime }) => {
  let dateString = "N/A";
  if (date && date.isValid) {
    dateString = date.toLocaleString(DateTime.DATE_SHORT);
  }

  return (
    <Column
      style={{
        gap: "0px"
      }}
    >
      <Typography variant="body1" color={"primary"}>
        {dateString}
      </Typography>
      {date && date.isValid && (
        <Typography variant="body2" color={"primary"}>
          {date.toRelative()}
        </Typography>
      )}
    </Column>
  );
};

const staffMemberWithRole = ({ getValue }: any) => {
  const value = getValue();
  const displayValue = getNameOrUsername(value);
  const permissions = config.permission;

  const roles = [];
  value?.roles?.forEach((role) => {
    const roleConfig = permissions[role];
    // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
    if (!isFalsy(roleConfig)) {
      roles.push(roleConfig.label);
    }
  });

  const roleString = roles.slice(0, 3).join(", ");

  const isMe = value?.user_id === store.getState().auth.user.user_id;

  return (
    <DefaultTableCell
      style={{
        overflowWrap: "anywhere"
      }}
    >
      <Typography color="primary" variant="body1">
        {displayValue ?? "User no longer exists."} {isMe && "(me)"}
      </Typography>
      <Typography color="secondary" variant="body1" fontSize={12}>
        {roleString ?? "N/A"}
      </Typography>
    </DefaultTableCell>
  );

};

const defaultCell = ({ getValue }) => {
  const value = getValue();

  const replaced = value?.split("_");
  const capitalized = replaced
    ?.map((text) => {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    })
    .join(" ");

  return (
    <DefaultTableCell>
      {capitalized !== "" ? capitalized : "N/A"}
    </DefaultTableCell>
  );
};

export { dateCell, defaultCell, dateWithRelative, staffMemberWithRole };
