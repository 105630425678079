import {
  useRef,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  ChangeEvent,
  Children,
  cloneElement
} from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import SearchPatientModal from "../Modal/SearchPatientModal/SearchPatientModal";
import { NextPageIcon } from "../../assets/images/icons";
import { PersonSearch, SupportAgent } from "@mui/icons-material";
import RolesEnum, {
  canSearchMemberByDevice,
  canSeeMemberDetails
} from "common/enums/RolesEnum";
import useRouteConfigByRole from "../../hooks/useRouteConfigByRole";
import MemberStatusEnum from "common/enums/MemberStatusEnum";

import NavbarInputField from "./NavbarInputField";
import TableSearchInputField from "./TableSearchInputField";
import { useGetMembersWithQueryParamsQuery } from "common/services/MemberService";
import MemberType from "common/types/MemberType";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import replace from "lodash.replace";
import { MEMBERS_PATH } from "../../routes/RouteComponents";
import { useTheme } from "@mui/material";
import StyledIconButton from "../Button/StyledIconButton";
import SearchBySerialNumberModal from "../Modal/SearchPatientModal/SearchBySerialNumberModal";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { trimText } from "common/helpers/helpers";

interface IProps {
  children?: ReactNode;
  containerStyles?: string;
  inputFieldType?: "navbar" | "table";
  onSubmitProps?: {
    userListFilters: {
      roles?: RolesEnum[];
      status?: MemberStatusEnum[];
      linked_entities?: MemberLinkedEntitiesEnum[];
    };
  };
  dataLoadingProps?: {
    setDataCallback?: (users: MemberType[] | undefined) => void;
    setDataLoadingCallback?: (isDataLoading: boolean) => void;
    setDataErrorCallback?: (error: any) => void;
  };
  clearOnClickOutside?: boolean;
  dataError?: any;
  searchContainerWidth?: string;
}

const UserSearchInput = (props: IProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [searchText, setSearchText] = useState<string>("");

  const [searchPatientModalOpen, setSearchPatientModalOpen] =
    useState<boolean>(false);

  const [searchBySerialNumberModalOpen, setSearchBySerialNumberModalOpen] =
    useState<boolean>(false);

  let {
    children,
    containerStyles,
    inputFieldType = "navbar",
    onSubmitProps = { userListFilters: {} },
    dataLoadingProps = {},
    clearOnClickOutside = false,
    searchContainerWidth = "clamp(200px, 250%, 414px)"
  } = props;
  const { userListFilters } = onSubmitProps || {};

  const {
    setDataCallback = () => {},
    setDataLoadingCallback = () => {},
    setDataErrorCallback = () => {}
  } = dataLoadingProps || {};

  const trimmedText = useMemo(() => {
    const trimmedText = trimText(searchText);

    return trimmedText;
  }, [searchText]);

  const {
    data: searchData,
    isFetching: isSearchFetching,
    error: searchErrors,
    isError: isSearchError
  } = useGetMembersWithQueryParamsQuery(
    {
      search: trimmedText,
      sort: "lastName,ASC,firstName,ASC",
      ...userListFilters
    },
    { skip: trimmedText.length === 0, refetchOnMountOrArgChange: true }
  );

  const searchResults = useMemo(() => {
    if (trimmedText.length === 0) return [];
    else return searchData;
  }, [searchData, trimmedText]);

  useEffect(() => {
    if (trimmedText.length === 0 || searchResults === undefined) {
      setDataCallback(undefined);
      return;
    }

    setDataCallback(searchResults);
  }, [searchResults, trimmedText]);
  useEffect(() => setDataLoadingCallback(isSearchFetching), [isSearchFetching]);
  useEffect(() => setDataErrorCallback(searchErrors), [searchErrors]);

  const roleConfig = useRouteConfigByRole();

  const containerRef = useRef<HTMLInputElement>();

  const patientDetailsEnabled = canSeeMemberDetails(roleConfig!.role);

  const resetSearchText = () => {
    setSearchText("");
  };

  useEffect(() => {
    const handleClickOutside = (event: ChangeEvent<HTMLInputElement>) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        clearOnClickOutside
      ) {
        resetSearchText();
      }
    };
    // @ts-ignore
    document.addEventListener("mousedown", handleClickOutside);
    // @ts-ignore
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [containerRef, resetSearchText, clearOnClickOutside]);

  const handleText = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  // This is not good. We need to improve this. Probably refactor the UserSearchInput and related files.
  if (children !== undefined) {
    children = Children.map(children, (child: any) => {
      return (
        <>
          {cloneElement(child, {
            data: searchResults,
            clearHandler: resetSearchText
          })}
        </>
      );
    });
  }

  return (
    <RowContainer containerStyles={containerStyles}>
      <SimpleSearchContainer
        searchContainerWidth={searchContainerWidth}
        ref={containerRef}
      >
        {inputFieldType === "navbar" && (
          <NavbarInputField
            searchText={searchText}
            handleText={handleText}
            isFetching={isSearchFetching}
            isSearchError={isSearchError}
            searchErrors={searchErrors}
            noSearchResults={searchResults?.length === 0}
          />
        )}
        {inputFieldType === "table" && (
          <TableSearchInputField
            searchText={searchText}
            handleText={handleText}
            isFetching={isSearchFetching}
          />
        )}
        {children}
      </SimpleSearchContainer>

      <StyledIconButton
        iconColor={theme.palette.primary.main}
        border="square"
        Icon={PersonSearch}
        testId="UserSearchInput-advSearch"
        onClick={() => {
          resetSearchText();
          setSearchPatientModalOpen(true);
        }}
      />

      {canSearchMemberByDevice(currentRole) && (
        <StyledIconButton
          iconColor={theme.palette.primary.main}
          border="square"
          Icon={SupportAgent}
          onClick={() => {
            setSearchBySerialNumberModalOpen(true);
          }}
        />
      )}

      <SearchPatientModal
        onSubmitProps={onSubmitProps}
        dataLoadingProps={dataLoadingProps}
        isOpen={searchPatientModalOpen}
        handleClose={() => {
          setSearchPatientModalOpen(false);
        }}
        disableSelection={!patientDetailsEnabled}
        handleSelectedUser={(user_id: string) => {
          if (patientDetailsEnabled) {
            const link = replace(MEMBERS_PATH, ":memberId", user_id);
            navigate(link);
          }
        }}
      />
      <SearchBySerialNumberModal
        isOpen={searchBySerialNumberModalOpen}
        handleClose={() => setSearchBySerialNumberModalOpen(false)}
        onDeviceSelected={(device) => {
          navigate(`/members/memberId/${device.patient_id}`);
        }}
      />
    </RowContainer>
  );
};

const RowContainer = styled.div<{ containerStyles: any }>`
  ${(props) => props.containerStyles}
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 10px;
`;

const SimpleSearchContainer = styled.div<{ searchContainerWidth: string }>`
  display: flex;
  align-self: center;
  width: ${({ searchContainerWidth }) => searchContainerWidth};
`;

export default UserSearchInput;
