import { QueryString_stringify } from "../helpers/QueryStringHelper";
import UserType from "../types/UserType";
import PageType from "../types/PageType";

import { apiPAB } from "./AxiosService";
import MemberStatusEnum from "../enums/MemberStatusEnum";

import ReduxTagEnum from "../enums/ReduxTagEnum";
import RolesEnum from "../enums/RolesEnum";
import { providesList } from "./ServicesCacheUtils";
import { getNameOrUsername } from "../helpers/helpers";
import UserTypeInner from "../types/UserTypeInner";
import UserLinkedEntitiesEnum from "../enums/UserLinkedEntitiesEnum";
import UserMetadataType from "../types/UserMetadataType";
export interface UserQueryParams {
  first?: string;
  last?: string;
  phone?: string;
  search?: string;
  roles?: RolesEnum[];
  size?: number;
  created_by?: string;
  enrolled_after?: string;
  enrolled_before?: string;
  status?: MemberStatusEnum[];
  carer_id?: string;
}

const providesTags = (result: UserType[] | undefined) => {
  return providesList<UserType, ReduxTagEnum.User>(
    result,
    ReduxTagEnum.User,
    (item: UserType) => item.user.user_id
  );
};

export const userService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.User]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getUserWithUsername: builder.query<
        UserType,
        { username: string; linked_entities?: UserLinkedEntitiesEnum[] }
      >({
        query: ({ username, linked_entities }) => {
          const queryParams = QueryString_stringify({
            linked_entities
          });
          return { url: `/users/${username}?${queryParams}`, method: "GET" };
        },
        providesTags: (result) => [
          { type: ReduxTagEnum.User, id: result?.user.user_id }
        ]
      }),
      getUserWithQueryParams: builder.query<
        UserType[],
        (PageType | UserQueryParams) & {
          linked_entities?: UserLinkedEntitiesEnum[];
        }
      >({
        query: (params) => {
          const queryParams = QueryString_stringify({
            ...params
          });
          return { url: `/users?${queryParams}`, method: "GET" };
        },
        transformResponse: (response: UserType[]) => {
          const finalResponse = response
            .filter((item) => item.user.is_test === false)
            .map((item) => {
              const displayName = getNameOrUsername(item.user);
              return {
                ...item,
                user: {
                  ...item.user,
                  fullname: displayName
                }
              };
            });
          return finalResponse;
        },
        providesTags
      }),

      getUserScheduleTypes: builder.query<any, {}>({
        query: () => {
          return { url: `/scheduletypes`, method: "GET" };
        }
      }),

      // Mutations
      createUser: builder.mutation<
        UserTypeInner,
        Omit<Partial<UserTypeInner>, "id">
      >({
        query: (data) => ({
          url: "/users",
          method: "POST",
          data
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.User, id: result?.user_id }
        ]
      }),
      changeUserStatus: builder.mutation<
        UserTypeInner,
        Omit<{ email: string; status: MemberStatusEnum }, "id">
      >({
        query: ({ email, status }) => ({
          url: `/users/${email}/status/${status}`,
          method: "PUT"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.User, id: result?.user_id }
        ]
      }),
      updateUserByUsername: builder.mutation<
        UserTypeInner,
        Omit<{ email: string; user: Partial<UserTypeInner> }, "id">
      >({
        query: ({ email, user }) => ({
          url: `/users/${email}`,
          method: "PUT",
          data: user
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.User, id: result?.user_id }] : []
      }),
      updateUserRoleByUsername: builder.mutation<
        UserTypeInner,
        Omit<{ email: string; roles: RolesEnum[] }, "id">
      >({
        query: ({ email, roles }) => ({
          url: `/users/${email}/roles`,
          method: "PUT",
          data: roles
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.User, id: result?.user_id }] : []
      }),
      updateMetadata: builder.mutation<
        UserMetadataType & { user_id: string },
        Omit<
          {
            email: string;
            extra?: { zoom_user_id?: string; zoom_phone?: string };
            athena_provider_id?: string;
            athena_dpt_id?: string;
            athena_dpt_name?: string;
            excluded_from_intake_flow?: boolean;
            excluded_from_scheduling?: boolean;
            states?: string[];
          },
          "id"
        >
      >({
        query: ({
          email,
          extra,
          athena_provider_id,
          athena_dpt_id,
          athena_dpt_name,
          excluded_from_intake_flow,
          excluded_from_scheduling,
          states
        }) => {
          return {
            url: `/users-metadata/${email}`,
            method: "PUT",
            data: {
              extra,
              athena_provider_id,
              athena_dpt_id,
              athena_dpt_name,
              excluded_from_intake_flow,
              excluded_from_scheduling,
              states
            }
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.User, id: result?.user_id }
        ]
      }),

      updateMetadataScheduleType: builder.mutation<
        UserMetadataType & { user_id: string },
        {
          schedule_type_id: string;
        }
      >({
        query: ({ user_id, schedule_type_id }) => {
          return {
            url: `/users-metadata/${user_id}/scheduletype`,
            method: "PUT",
            data: {
              schedule_type_id
            }
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.User, id: result?.user_id }
        ]
      }),

      changePassword: builder.mutation<
        UserTypeInner,
        Omit<
          { email: string; new_password: string; old_password: string },
          "id"
        >
      >({
        query: ({ email, new_password, old_password }) => ({
          url: `/users/${email}/password`,
          method: "PUT",
          data: { new_password, old_password }
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: ReduxTagEnum.User, id: result?.user_id }] : []
      }),

      resetPassword: builder.mutation<
        UserType,
        Omit<{ username: string; new_password: string }, "id">
      >({
        query: ({ username, new_password }) => ({
          url: `/login/reset/${username}`,
          method: "PUT",
          data: { new_password }
        })
      })
    })
  });

export const {
  useGetUserWithUsernameQuery,
  useGetUserWithQueryParamsQuery,
  useLazyGetUserWithUsernameQuery,
  useLazyGetUserWithQueryParamsQuery,
  useGetUserScheduleTypesQuery,
  useCreateUserMutation,
  useChangeUserStatusMutation,
  useUpdateUserByUsernameMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useUpdateUserRoleByUsernameMutation,
  useUpdateMetadataMutation,
  useUpdateMetadataScheduleTypeMutation
} = userService;
