import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  RECENT_ORDERS,
  UNASSIGNED_MEMBERS,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  MEMBER_CHART,
  COMPLETED_TODOS,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  NURSES_SCHEDULES,
  NURSE_SCHEDULES,
  LOGOUT,
  VISITS,
  START_INTAKE
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_SECTIONS,
  SIDEBAR_CLINICAL_TEAMS
} from "../RouteSidebar";
import { PersonIcon } from "../../assets/images/icons";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import RolesEnum from "common/enums/RolesEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";

const THN_MANAGER = {
  label: "Telehealth Nurse Manager",
  routes: [
    {
      path: "/",
      components: [NURSE_LIST({})]
    },
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: [
        ALL_MEMBERS({
          tableColumns: [
            { name: "name" },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    START_INTAKE,
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    {
      path: "/unassigned-members",
      components: [UNASSIGNED_MEMBERS]
    },
    ...MEMBER_CHART(RolesEnum.THN_MANAGER),
    ...MEMBER_CHART_CALENDARING(),
    {
      // my patients route
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15
        }),
        RECENT_ORDERS()
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-team",
      components: [
        {
          type: RouteComponentTypeEnum.MY_TEAM,
          componentParams: {
            componentHeader: "Team Details",
            externalLink: "/staff/staffId/:userId/assignments",
            tableColumns: [
              { name: "teamMemberName" },
              { name: "patientsAssigned" },
              // TBD - fix this MTD time entered field on the backend before commenting this back in
              // { name: "myTeamTimeEntered", size: 200 },
              { name: "patientCount", size: 150 },
              { name: "deleteTeamMember" }
            ]
          }
        }
      ]
    },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },

    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    COMPLETED_TODOS,
    MY_TODOS,
    ...NURSES_SCHEDULES,
    NURSE_SCHEDULES,
    NURSE_SCHEDULE,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    LOGOUT,
    VISITS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "My Nurses",
          itemLink: "/my-team",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: "/nurses-schedules/schedules",

          ItemIcon: PersonIcon
        },
        SIDEBAR_CLINICAL_TEAMS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_MY_MEMBERS,
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default THN_MANAGER;
