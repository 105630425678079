import RolesEnum from "common/enums/RolesEnum";
import { PersonIcon } from "../../assets/images/icons";

import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  CANCELED_MEMBERS,
  RETENTION,
  UNASSIGNED_MEMBERS,
  NPN_LIST,
  ALL_TEAMS,
  CREATE_TEAM,
  NPN_DETAILS,
  TEAM_DETAILS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  STAFF_DETAILS,
  NURSE_SCHEDULE,
  MY_TODOS,
  COMPLETED_TODOS,
  NURSES_SCHEDULES,
  PROVIDER_SCHEDULES,
  PROVIDER_SCHEDULES_SIDEBAR,
  LOGOUT,
  VISITS,
  PROVIDER_LIST
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MIGRATE_MEMBER,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import EnvVars from "common/config/EnvVars";
import FeatureFlags from "common/config/FeatureFlags";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [SIDEBAR_CREATE_TEAM]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: "/nurses",
          ItemIcon: PersonIcon
        },
        {
          itemName: "Nurses Schedules",
          itemLink: "/nurses-schedules/schedules",
          ItemIcon: PersonIcon
        },
        SIDEBAR_NPNS,
        SIDEBAR_CLINICAL_TEAMS
      ]
    }
  ];
  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [
        {
          itemName: "All Providers",
          itemLink: "/providers",
          ItemIcon: PersonIcon
        },
        PROVIDER_SCHEDULES_SIDEBAR
      ]
    });
  }

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.members,
    items: [
      SIDEBAR_ALL_MEMBERS,
      SIDEBAR_UNASSIGNED_MEMBERS,
      SIDEBAR_INACTIVE_MEMBERS,

      SIDEBAR_ORDERS
    ]
  });
  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.inactives,
    items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
  });
  sidebarItems.push(SIDEBAR_MIGRATE_MEMBER);
  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);
  return sidebarItems;
};

const NURSE_DIRECTOR = {
  label: "Nurse Director",
  routes: [
    {
      path: "/",
      components: [NURSE_LIST({})]
    },
    {
      path: "/migrate-patient",
      components: [
        {
          type: "migrate-patient"
        }
      ]
    },
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS,
        TeamTypeEnum.NURSE_MANAGERS
      ]
    }),
    TEAM_DETAILS,
    {
      path: "/nurses",
      components: [NURSE_LIST({})]
    },
    NPN_LIST({}),
    NPN_DETAILS,
    STAFF_DETAILS,
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(true)] },
    {
      path: "/unassigned-members",
      components: [UNASSIGNED_MEMBERS]
    },
    ...MEMBER_CHART(RolesEnum.NURSE_DIRECTOR),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    REPORT_LIST,
    REPORT_DETAILS,
    CANCELED_MEMBERS,
    RETENTION({}),
    REPORT_DETAILS_BY_MEMBER,
    CREATE_TEAM,
    ...NURSES_SCHEDULES,
    NURSE_SCHEDULE,
    MY_TODOS,
    COMPLETED_TODOS,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/providers",
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    LOGOUT,
    VISITS
  ],
  sidebar: sidebar()
};

export default NURSE_DIRECTOR;
