import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NPN_LIST,
  NPN_DETAILS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  UNASSIGNED_MEMBERS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  MY_TODOS,
  COMPLETED_TODOS,
  LOGOUT,
  PROVIDER_SCHEDULES_SIDEBAR,
  PROVIDER_SCHEDULES,
  PROVIDER_LIST,
  NURSE_SCHEDULES
} from "./../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT,
  SIDEBAR_UNASSIGNED_MEMBERS
} from "./../RouteSidebar";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import FeatureFlags from "common/config/FeatureFlags";
import { PersonIcon } from "../../assets/images/icons";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        SIDEBAR_NPNS,
        SIDEBAR_CLINICAL_TEAMS,
        {
          itemName: "Nurse Schedules",
          itemLink: "/nurse-schedules",
          ItemIcon: PersonIcon
        }
      ]
    }
  ];
  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [PROVIDER_SCHEDULES_SIDEBAR]
    });
  }
  sidebarItems.push(SIDEBAR_REPORTS, SIDEBAR_SUPPORT);

  return sidebarItems;
};

const NPN_MANAGER = {
  label: "Intake Nurse Manager",
  routes: [
    {
      path: "/",
      components: [NURSE_LIST({})]
    },
    {
      path: "/nurses",
      components: [NURSE_LIST({})]
    },
    {
      path: "/nurses/nurseId/:userId",
      components: [{ type: RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT }]
    },
    STAFF_DETAILS,
    NPN_LIST({}),
    NPN_DETAILS,
    STAFF_DETAILS,
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" },
            { name: "patientActions", size: 75 }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(true)] },
    {
      path: "/unassigned-members",
      components: [UNASSIGNED_MEMBERS]
    },
    ...MEMBER_CHART(RolesEnum.NPN_MANAGER),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/members/nurseId/:nurseId",
      components: [
        MEMBERS_WITH_NO_ACTIVITY({}),
        ASSIGNED_MEMBERS({
          recentOrdersSinceDays: 15
        })
      ]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        },
        ALL_RECENT_ORDERS()
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/members/memberId/:memberId/start-intake",
      components: [
        {
          type: RouteComponentTypeEnum.MEMBER_DETAILS_START_INTAKE,
          componentParams: {
            hideReadings: false
          }
        }
      ]
    },
    ORDER_DETAILS(),
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    MY_TODOS,
    COMPLETED_TODOS,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/providers",
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    NURSE_SCHEDULES,
    LOGOUT
  ],
  sidebar: sidebar()
};

export default NPN_MANAGER;
