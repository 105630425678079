import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  ORDER_DETAILS,
  CANCELED_MEMBERS,
  RETENTION,
  REPORT_LIST,
  REPORT_DETAILS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  LOGOUT
} from "../RouteComponents";

import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_INACTIVE_CANCELED_MEMBERS,
  SIDEBAR_RETENTION,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";

const RETENTION_SPECIALIST = {
  label: "Retention Specialist",
  routes: [
    {
      path: "/",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(false)] },
    ...MEMBER_CHART(RolesEnum.RETENTION_SPECIALIST),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    CANCELED_MEMBERS,
    RETENTION({}),
    REPORT_LIST,
    REPORT_DETAILS,
    LOGOUT
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.inactives,
      items: [SIDEBAR_RETENTION, SIDEBAR_INACTIVE_CANCELED_MEMBERS]
    },
    SIDEBAR_REPORTS
  ]
};

export default RETENTION_SPECIALIST;
