import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";

import SubmitEncounterForm from "../../MemberDetails/Header/SubmitEncounterForm";
import { useCompleteVisitMutation } from "common/services/VisitsService";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../../components/ErrorComponent";
import { isFalsy } from "common/helpers/helpers";
import { styled } from "@mui/material";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";

const SubmitEncounterContainer = styled("div")`
  margin: 0px 0px 0px -10px;
`;

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
}

const VisitFieldBillingEntryNurse = ({ field, visit }: IProps) => {
  const [data, setData] = useState<{
    reason: string;
    timeSpent: number | string;
    visitType: string;
  }>();

  const navigate = useNavigate();
  const [completeVisitMutation, { isLoading, isSuccess, error }] =
    useCompleteVisitMutation();

  const handleComplete = () => {
    completeVisitMutation({ visit_id: visit.visit_id });
  };

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess]);

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  return (
    <>
      <SubmitEncounterContainer>
        <SubmitEncounterForm
          memberId={visit.patient_id}
          onChange={({ reason, timeSpent, visitType }) => {
            setData({
              reason: reason?.value,
              timeSpent,
              visitType: visitType?.value
            });
          }}
          hideSubmitButton
        />
      </SubmitEncounterContainer>

      <br />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={handleComplete}
        disabled={disabled || isFalsy(data?.timeSpent)}
      >
        Complete Call
      </LoadingButton>

      <br />
      <ErrorComponent error={error} />
    </>
  );
};

export default VisitFieldBillingEntryNurse;
