import {
  SIDEBAR_DASHBOARD,
  SIDEBAR_MEMBER_MANAGEMENT,
  SIDEBAR_REPORTS
} from "../RouteSidebar";
import {
  REGISTER_MEMBER,
  REQUEST_CONSENTS,
  MY_CONVERSIONS,
  MY_REGISTRATIONS,
  MEMBER_CHART,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  LOGOUT
} from "../RouteComponents";
import RolesEnum from "common/enums/RolesEnum";

const NPS = {
  label: "New Member Specialist",
  routes: [
    { path: "/", components: [REGISTER_MEMBER] },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    { path: "/register-member", components: [REGISTER_MEMBER] },
    { path: "/request-consents", components: [REQUEST_CONSENTS] },
    { path: "/my-conversions", components: [MY_CONVERSIONS] },
    { path: "/my-registrations", components: [MY_REGISTRATIONS] },
    ...MEMBER_CHART(RolesEnum.NPS),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    LOGOUT
  ],
  sidebar: [SIDEBAR_DASHBOARD, SIDEBAR_MEMBER_MANAGEMENT(true), SIDEBAR_REPORTS]
};

export default NPS;
