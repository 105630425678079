import FeatureFlags from "common/config/FeatureFlags";
import { CustomTooltip } from "../../styling/StyleComponents";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";
import styled from "@emotion/styled";
import { gray } from "../../styling/colors";
import { Button } from "@mui/material";
import { useEffect } from "react";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useCreateVisitMutation } from "common/services/VisitsService";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "common/redux";
import { isFalsy } from "common/helpers/helpers";
import replace from "lodash.replace";
import { CAREFLOW_PATH } from "../../routes/RouteComponents";
import { useNavigate, useParams } from "react-router-dom";
import { setInitialState } from "common/redux/VisitsSlice";

const StyledButton = styled(Button)`
  background: white;
  padding: 5px;
  &:hover: {
    backgroundColor: ${gray[25]}
    pointer: cursor;
  }
  lineHeight: 1.3;
`;

const StartCareFlow = ({
  isSameDay = true,
  visitsRequest,
}: {
  isSameDay?: boolean;
  visitsRequest: any;
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { data: currentVisit, isLoading } = useGetCurrentVisit({
    staffId: user?.user_id
  });
  const { visitId: existingVisitId } = useParams();

  const [
    createVisitMutation,
    {
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      data: createVisitResult,
      reset: resetCreateVisit
    }
  ] = useCreateVisitMutation();

  // if visit is created with care flow, navigate to the care flow
  useEffect(() => {
    if (createVisitIsSuccess && createVisitResult.care_flow) {
      let careFlowLink = replace(
        CAREFLOW_PATH,
        ":visitId",
        createVisitResult?.visit_id
      );
      dispatch(setInitialState());
      navigate(careFlowLink);
    }
  }, [createVisitIsSuccess]);

  if (isFalsy(visitsRequest.motivation_reason)) 
    visitsRequest.motivation_reason = VisitMotivationTypesEnum.APPOINTMENT;

  return (
    <>
      {FeatureFlags(user?.user_id).CARE_FLOWS && isSameDay && (
        <CustomTooltip
          title={
            currentVisit &&
            "Cannot start a new task because there is already one in progress"
          }
          placement="bottom"
        >
          <div>
            <StyledButton
              variant="outlined"
              color="primary"
              disabled={
                !isFalsy(currentVisit) || !isFalsy(existingVisitId) || isLoading || createVisitLoading
              }
              onClick={async () => {
                await createVisitMutation({
                  staff_id: user?.user_id,
                  patient_id: visitsRequest.patient_id,
                  body: {
                    ...visitsRequest,
                    staff_id: user?.user_id,
                  },
                  with_care_flow: true
                });
              }}
            >
              Start
            </StyledButton>
          </div>
        </CustomTooltip>
      )}
    </>
  );
};

export default StartCareFlow;
