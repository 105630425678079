import { Button } from "@mui/material";
import styled from "@emotion/styled";
import Table from "../../../components/Table/Table";
import MemberType from "common/types/MemberType";
import EditRelationshipNoteForm from "../EditRelationshipNoteForm";
import { Alert_show } from "common/helpers/AlertHelper";
import { RootState, dispatch } from "common/redux";
import { useSelector } from "react-redux";
import { canEditRelationshipNotes } from "common/enums/RolesEnum";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IProps {
  member: MemberType;
}

const VisitFieldMemberRelationshipNotes = ({
  member,
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const isEditDeleteAllowed = canEditRelationshipNotes(currentRole);

  const columns = [
    { name: "relationshipNotesCategory", size: 100 },
    { name: "relationshipNotesNote", size: 300 },
    { name: "relationshipNotesLastModified", size: 100 },
    { name: "relationshipNotesActions", size: 100 }
  ];

  return (
    <>
      <br />
      {isEditDeleteAllowed && (
        <Button
          variant="outlined"
          onClick={() => {
            Alert_show({
              dispatch,
              id: "editRelationshipNote",
              title: "Add Relationship Note",
              content: <EditRelationshipNoteForm member={member} />,
              size: "large",
              buttons: []
            });
          }}
        >
          Add New Relationship Note
        </Button>
      )}
      {member?.relationship_notes && (
        <Table
          tableColumns={columns}
          data={member?.relationship_notes}
          tableProps={{ dispatch, member, isEditDeleteAllowed }}
          noDataText="No relationship notes have been added for this member"
        />
      )}
    </>
  );
};

export default VisitFieldMemberRelationshipNotes;
