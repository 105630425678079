import { useState } from "react";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";

import CareFlowFieldComponentEnum from "common/enums/CareFlowFieldComponentEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

import { Column } from "../../../styling/StyleComponents";
import GlucoseChart from "../../MemberDetails/Readings/GlucoseChart";
import BloodPressureChart from "../../MemberDetails/Readings/BloodPressureChart";
import PulseChart from "../../MemberDetails/Readings/PulseChart";
import OximeterChart from "../../MemberDetails/Readings/OximeterChart";
import WeightScaleChart from "../../MemberDetails/Readings/WeightScaleChart";
import MemberAgreements from "../../MemberDetails/Profile/MemberAgreements";
import OrderHistoryTable from "../../OrderDevices/OrderHistoryTable";
import VisitFieldConnectWithMember from "./VisitFieldConnectWithMember";
import VisitFieldMemberRelationshipNotes from "./VisitFieldMemberRelationshipNotes";
import UpcomingAppointments from "../../MemberDetails/Appointments/UpcomingAppointments";
import { DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING } from "../../MemberDetails/Appointments/constants";
import VisitFieldBillingEntryNurse from "./VisitFieldBillingEntryNurse";

import VisitFieldGenerateNotes from "./VisitFieldGenerateNotes";
import VisitFieldStartEncounter from "./VisitFieldStartEncounter";

import { gray } from "../../../styling/colors";
import VisitFieldQuote from "./VisitFieldQuote";
import VisitFieldTrendBloodPressure from "./VisitFieldTrendBloodPressure";
import VisitFieldTrendGlucose from "./VisitFieldTrendGlucose";
import VisitFieldTabGroup from "./VisitFieldTabGroup";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import RefillForm from "../../OrderDevices/RefillForm";
import VisitFieldDateTime from "./VisitFieldDateTime";
import VisitContentField from "./VisitContentField";
import VisitFieldAgendaSetting from "./VisitFieldAgendaSetting";
import VisitFieldCriticalIssues from "./VisitFieldCriticalIssues";
import VisitFieldGoalManagement from "./VisitFieldGoalManagement";
import VisitFieldBillingEntryTimeSpent from "./VisitFieldBillingEntryTimeSpent";
import VisitFieldProviderDropdown from "./VisitFieldProviderDropdown";
import VisitFieldDeviceSetupGuide from "./VisitFieldDeviceSetupGuide";
import VisitFieldPreCallLastVisitFollowUp from "./VisitFieldPreCallLastVisitFollowUp";
import VisitFieldCarePlanWeeklyVisits, {
  VisitFieldCarePlanWeeklyVisitsConvinceQuote
} from "./VisitFieldCarePlanWeeklyVisits";
import {
  VisitFieldUrgentAlertBanners,
  VisitFieldUrgentAlertActions
} from "./VisitFieldUrgentAlerts";
import VisitFieldMemberReadingsReview from "./VisitFieldMemberReadingsReview";
import VisitFieldReadingTrends from "./VisitFieldReadingTrends";

const VisitContentComponentField = ({
  field,
  width,
  visit
}: {
  field: CareFlowFieldType;
  width: number;
  visit: GetVisitResponseType;
}) => {
  const memberId = visit?.patient_id;

  const getLinkedEntities = () => {
    switch (field.component_type) {
      case CareFlowFieldComponentEnum.MISSING_AGREEMENTS_TABLE:
        return [MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS_FULL];
      case CareFlowFieldComponentEnum.MEMBER_RELATIONSHIP_NOTES:
        return [MemberLinkedEntitiesEnum.RELATIONSHIP_NOTES];
      case CareFlowFieldComponentEnum.CARE_PLAN_WEEKLY_VISITS:
        return [MemberLinkedEntitiesEnum.NURSE];
      case CareFlowFieldComponentEnum.READING_TRENDS_BACKGROUND:
        return [MemberLinkedEntitiesEnum.CARE_PLAN];
      default:
        return [];
    }
  };

  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: getLinkedEntities()
    },
    { skip: memberId === undefined }
  );

  const [showAverages, setShowAverages] = useState<boolean>(true);
  const [dateFilter, setDateFilter] = useState<number>(7);

  const [addressChanged, setAddressChanged] = useState<boolean>(false);
  const [addressSource, setAddressSource] = useState<AddressSourceEnum>(
    AddressSourceEnum.ATHENA
  );

  const handleDataChange = ({ dateFilter, showAverages }) => {
    setDateFilter(dateFilter);
    setShowAverages(showAverages);
  };

  switch (field.component_type) {
    case CareFlowFieldComponentEnum.GLUCOSE_READING_CHART:
      return (
        <Column>
          <GlucoseChart
            patient={member}
            defaultDays={7}
            dateFilter={dateFilter}
            showAverages={showAverages}
            handleDataChange={handleDataChange}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.BLOOD_PRESSURE_READING_CHART:
      return (
        <Column>
          <BloodPressureChart
            patient={member}
            defaultDays={7}
            dateFilter={dateFilter}
            showAverages={showAverages}
            handleDataChange={handleDataChange}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.PULSE_READING_CHART:
      return (
        <Column>
          <PulseChart
            patient={member}
            defaultDays={7}
            dateFilter={dateFilter}
            showAverages={showAverages}
            handleDataChange={handleDataChange}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.OXIMETER_READING_CHART:
      return (
        <Column>
          <OximeterChart
            patient={member}
            defaultDays={7}
            dateFilter={dateFilter}
            showAverages={showAverages}
            handleDataChange={handleDataChange}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.WEIGHT_SCALE_READING_CHART:
      return (
        <Column>
          <WeightScaleChart
            patient={member}
            defaultDays={7}
            dateFilter={dateFilter}
            showAverages={showAverages}
            handleDataChange={handleDataChange}
          />
        </Column>
      );
    case CareFlowFieldComponentEnum.MISSING_AGREEMENTS_TABLE:
      const hasChild = field?.subfields?.length > 0;
      return (
        <>
          <MemberAgreements
            hideCompletedAgreements
            hideHeader
            patient={member}
            width={width}
          >
            {hasChild &&
              field.subfields.map((item) => {
                return (
                  <VisitContentField
                    field={item}
                    visit={visit}
                    member={member}
                  />
                );
              })}
          </MemberAgreements>
        </>
      );
    case CareFlowFieldComponentEnum.ORDER_HISTORY:
      return (
        <OrderHistoryTable memberId={memberId} memberDetails width={width} />
      );
    case CareFlowFieldComponentEnum.CONNECT_WITH_MEMBER:
      return (
        <VisitFieldConnectWithMember
          member={member}
          field={field}
          visit={visit}
        />
      );
    case CareFlowFieldComponentEnum.GOAL_MANAGEMENT:
      return <VisitFieldGoalManagement memberId={memberId} />;
    case CareFlowFieldComponentEnum.MEMBER_RELATIONSHIP_NOTES:
      return <VisitFieldMemberRelationshipNotes member={member} />;
    case CareFlowFieldComponentEnum.ORDER_REFILLS:
      return (
        <RefillForm
          memberId={memberId}
          setAddressChanged={setAddressChanged}
          addressChanged={addressChanged}
          addressSource={addressSource}
          setAddressSource={setAddressSource}
        />
      );
    case CareFlowFieldComponentEnum.UPCOMING_APPOINTMENTS: {
      const now = DateTime.now().endOf("minute");
      const endDate = now
        .startOf("day")
        .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });
      return (
        <UpcomingAppointments
          memberId={memberId}
          startDate={now}
          endDate={endDate}
          isStaffDetails={false}
        />
      );
    }
    case CareFlowFieldComponentEnum.DYNAMIC_AGENDA_SETTING:
      return <VisitFieldAgendaSetting field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.DYNAMIC_CRITICAL_ISSUES:
      return <VisitFieldCriticalIssues field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.BILLING_ENTRY_NURSE:
      return <VisitFieldBillingEntryNurse field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.GENERATE_NOTES:
      return <VisitFieldGenerateNotes field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.START_ENCOUNTER:
      return <VisitFieldStartEncounter visit={visit} />;
    case CareFlowFieldComponentEnum.END_ENCOUNTER:
      return <VisitFieldStartEncounter visit={visit} />;
    case CareFlowFieldComponentEnum.TAB_GROUP:
      return <VisitFieldTabGroup field={field} visit={visit} member={member} />;
    case CareFlowFieldComponentEnum.QUOTE:
      return <VisitFieldQuote field={field} />;
    case CareFlowFieldComponentEnum.READING_TRENDS_BACKGROUND:
      return <VisitFieldReadingTrends member={member} />;
    case CareFlowFieldComponentEnum.BLOOD_PRESSURE_7D_AVG:
      return (
        <VisitFieldTrendBloodPressure field={field} days={7} member={member} />
      );
    case CareFlowFieldComponentEnum.GLUCOSE_7D_AVG:
      return <VisitFieldTrendGlucose field={field} days={7} member={member} />;
    case CareFlowFieldComponentEnum.BLOOD_PRESSURE_30D_AVG:
      return (
        <VisitFieldTrendBloodPressure field={field} days={30} member={member} />
      );
    case CareFlowFieldComponentEnum.GLUCOSE_30D_AVG:
      return <VisitFieldTrendGlucose field={field} days={30} member={member} />;
    case CareFlowFieldComponentEnum.DATETIME:
      return <VisitFieldDateTime field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.BILLING_ENTRY_TIME_ENTRY:
      return <VisitFieldBillingEntryTimeSpent field={field} visit={visit} />;
    case CareFlowFieldComponentEnum.DEVICE_SETUP_GUIDE:
      return <VisitFieldDeviceSetupGuide />;
    case CareFlowFieldComponentEnum.PROVIDER_DROPDOWN:
      return (
        <VisitFieldProviderDropdown
          member={member}
          field={field}
          visit={visit}
        />
      );
    case CareFlowFieldComponentEnum.PRECALL_LAST_VISIT_FOLLOWUP:
      return (
        <VisitFieldPreCallLastVisitFollowUp
          field={field}
          visit={visit}
          member={member}
        />
      );
    case CareFlowFieldComponentEnum.CARE_PLAN_WEEKLY_VISITS:
      return (
        <VisitFieldCarePlanWeeklyVisits
          field={field}
          visit={visit}
          member={member}
        />
      );
    case CareFlowFieldComponentEnum.CARE_PLAN_WEEKLY_VISITS_CONVINCE_QUOTE:
      return <VisitFieldCarePlanWeeklyVisitsConvinceQuote />;
    case CareFlowFieldComponentEnum.URGENT_ALERTS_BANNERS:
      return <VisitFieldUrgentAlertBanners member={member} />;
    case CareFlowFieldComponentEnum.URGENT_ALERT_ACTIONS:
      return <VisitFieldUrgentAlertActions visit={visit} member={member} />;
    case CareFlowFieldComponentEnum.MEMBER_READINGS_REVIEW:
      return <VisitFieldMemberReadingsReview />;
    default:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.component_type} COMPONENT IS UNDER CONSTRUCTION
        </Typography>
      );
  }
};

export default VisitContentComponentField;
