import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import RolesEnum from "common/enums/RolesEnum";
import MemberType from "common/types/MemberType";

import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import { useGetAllPatientList } from "../../hooks/data_loaders/useGetAllPatientList";
import {
  CustomDatePicker,
  IHeaderProps,
  Spinner,
  TableComponentContainer
} from "../../styling/StyleComponents";
import useRouteConfigByRole from "../../hooks/useRouteConfigByRole";
import { TableColumn } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DateFilterFieldEnum from "../../enums/DateFilterFieldEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import ErrorComponent from "../../components/ErrorComponent";
import ContactAttemptModal from "../../components/Modal/ContactAttemptModal";
import ContactAttemptDetailsModal from "../../components/Modal/ContactAttemptDetailsModal";
import MemberRetentionActionsType from "common/types/MemberRetentionActionsType";
import MemberStatusReasonEnum from "common/enums/MemberStatusReasonEnum";
import SurveyModal from "../PatientData/Survey/SurveyModal";
import {
  useGetLicensedStatesQuery,
  useGetMemberWithUsernameQuery,
  useGetMembersWithQueryParamsQuery
} from "common/services/MemberService";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { FilterAltOutlined, LoopOutlined, Search } from "@mui/icons-material";
import DebouncedInput from "../../components/Input/DebouncedInput";
import { firstLastUsernameSearch, getTextWidth } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import DateRangePickerModal from "../../components/Modal/DateRangePickerModal";
import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { trimText } from "common/helpers/helpers";

const TABLE_INPUT_WIDTH = "clamp(200px, 250%, 350px)";

const StyledInput = styled(DebouncedInput)`
  background: #ffffff;
  width: ${TABLE_INPUT_WIDTH};
`;

const StyledSpinner = styled(Spinner)`
  left: unset;
  right: 40px;
  top: 12px;
`;

enum TabPanelIndex {
  inactive = 0,
  neverEngaged = 1,
  requestsToCancel = 2
}

const Row = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DATE_FORMAT = "MM/dd/yyyy";

const getRouteFromTabIndex = (tabIndex: number) => {
  switch (tabIndex) {
    case TabPanelIndex.inactive:
      return "inactive";
    case TabPanelIndex.neverEngaged:
      return "never-engaged";
    case TabPanelIndex.requestsToCancel:
      return "requests-to-cancel";
  }
};

const getIndexFromRoute = (route: string) => {
  switch (route) {
    case "inactive":
      return TabPanelIndex.inactive;
    case "never-engaged":
      return TabPanelIndex.neverEngaged;
    case "requests-to-cancel":
      return TabPanelIndex.requestsToCancel;
    default:
      return TabPanelIndex.inactive;
  }
};

interface IProps extends IHeaderProps {
  userListFilters: {
    roles: RolesEnum[];
    status: MemberStatusEnum[];
    linked_entities?: MemberLinkedEntitiesEnum[];
    status_reason?: MemberStatusReasonEnum[];
  };
  filterByAssignedNurse?: boolean;
  loadReadingsEncounters: boolean;
  externalLink?: string;
  tableColumns: TableColumn[];
  tableHeader: string;
  dateFilterField?: DateFilterFieldEnum;
  filterBy?: (item: MemberType) => boolean;
  sortBy?: (a: MemberType, b: MemberType) => number;
}

const InactivePatientList = ({
  userListFilters,
  loadReadingsEncounters,
  filterByAssignedNurse,
  tableColumns,
  externalLink,
  tableHeader,
  dateFilterField,
  filterBy,
  sortBy
}: IProps) => {
  const location = useLocation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const [assignNurseModalOpen, setAssignNurseModalOpen] =
    useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);
  const [contactAttemptSendModalOpen, setContactAttemptSendModalOpen] =
    useState<boolean>(false);
  const [surveyModalOpen, setSurveyModalOpen] = useState<boolean>(false);

  const [selectedRetentionActions, setSelectedRetentionActions] =
    useState<MemberRetentionActionsType[]>(undefined);

  const [tabIndex, setTabIndex] = useState<number>(getIndexFromRoute(tabId));

  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [retrievedData, setRetrievedData] = useState<boolean>(false);

  const [dateRangePicking, setDateRangePicking] = useState<"last_contact">();
  const [lastContactRange, setLastContactRange] =
    useState<DateTime[]>(undefined);

  const pathname = useMemo(() => {
    const paths = location.pathname.split("/");
    if (paths?.length === 3) paths.pop();

    return paths.join("/");
  }, [location]);

  const startDate = useMemo(() => {
    const now = DateTime.now();
    return dateFilterField != undefined ? now.minus({ days: 30 }) : undefined;
  }, []);
  const endDate = useMemo(() => {
    const now = DateTime.now();
    return dateFilterField != undefined ? now : undefined;
  }, []);

  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.RETENTION_FILTER).then((value) => {
      if (value) {
        const { lastContactRange, states } = JSON.parse(value);

        setSelectedStates(states);
        if (lastContactRange) {
          setLastContactRange(
            lastContactRange.map((item) => DateTime.fromISO(item))
          );
        }
      }
      setRetrievedData(true);
    });
  }, []);

  useEffect(() => {
    if (retrievedData === false) return;
    const json = JSON.stringify({
      lastContactRange: lastContactRange
        ? lastContactRange.map((item) => item.toISO())
        : lastContactRange,
      states: selectedStates
    });
    StorageHelper.setItem(StorageEnum.RETENTION_FILTER, json);
  }, [lastContactRange, selectedStates, retrievedData]);

  const setModalStateCallback = (value: string) => {
    setAssignNurseModalOpen(value === "open");
  };

  const setProviderModalStateCallback = (value: string) => {
    setProviderModalOpen(value === "open");
  };

  const onContactAttemptClose = () => {
    setContactAttemptSendModalOpen(false);
  };

  const openContactAttemptModalHandler = () => {
    setContactAttemptSendModalOpen(true);
  };

  const roleConfig = useRouteConfigByRole();

  const hasActions = tableColumns?.find(
    (column) => column.name === "patientActions"
  );

  const status = useMemo(() => {
    switch (tabIndex) {
      case TabPanelIndex.inactive:
        return [MemberStatusEnum.INACTIVE];
      case TabPanelIndex.neverEngaged:
        return [MemberStatusEnum.NEVER_ENGAGED];
      case TabPanelIndex.requestsToCancel:
        return [MemberStatusEnum.CANCELLATION_REQUESTED];
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabId === "notset") return;
    StorageHelper.setItem(
      StorageEnum.RETENTION_ROUTE,
      getRouteFromTabIndex(tabIndex)
    );
  }, [tabIndex]);

  useEffect(() => {
    if (tabId === "notset") {
      StorageHelper.getItem(StorageEnum.RETENTION_ROUTE).then((route) => {
        if (route) {
          setTabIndex(getIndexFromRoute(route));
          navigate(pathname + "/" + route);
        } else {
          navigate(pathname + "/inactive");
        }
      });
    }
  }, [tabId]);

  const listFilters = {
    ...userListFilters,
    status: status,
    carer_id: filterByAssignedNurse ? user?.user_id : undefined
  };

  const {
    data,
    isFetching,
    isError,
    error: allPatientListError,
    isDataSummarized
  } = useGetAllPatientList(
    dateFilterField === DateFilterFieldEnum.ENROLLED
      ? {
          ...listFilters,
          enrolled_after: startDate?.toISODate(),
          enrolled_before: endDate?.toISODate()
        }
      : listFilters,
    loadReadingsEncounters,
    dateFilterField === DateFilterFieldEnum.CREATED_AT
      ? {
          field: dateFilterField,
          startDate,
          endDate
        }
      : undefined
  );

  const { data: licensedStatesData, error: licensedStatesError } =
    useGetLicensedStatesQuery();

  const processedData = useMemo(() => {
    if (data === undefined) return undefined;

    let copiedData = [...data];
    if (filterBy) {
      copiedData = copiedData?.filter((item) => filterBy(item));
    }

    if (sortBy) {
      copiedData = data?.sort(sortBy);
    }
    return copiedData;
  }, [data, filterBy, sortBy, selectedStates]);

  const { data: selectedPatient } = useGetMemberWithUsernameQuery(
    {
      username: selectedPatientId
    },
    {
      skip: selectedPatientId === undefined
    }
  );

  const error = licensedStatesError || allPatientListError;

  const [searchText, setSearchText] = useState<string>("");
  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const trimmedText = useMemo(() => {
    const trimmedText = trimText(searchText);

    return trimmedText;
  }, [searchText]);

  // filter original data by search text
  const originalFilteredData = useMemo(() => {
    if (trimmedText.length === 0) return processedData;
    else
      return firstLastUsernameSearch(
        trimmedText,
        processedData,
        "patient.username",
        "fullname"
      );
  }, [processedData, trimmedText]);

  const { data: searchData, isFetching: isSearchFetching } =
    useGetMembersWithQueryParamsQuery(
      {
        search: trimmedText,
        sort: "lastName,ASC,firstName,ASC",
        ...userListFilters,
        status: status
      },
      {
        // if we don't find any results in the original data, then we need to fetch results from api
        skip: trimmedText.length === 0 || originalFilteredData?.length > 0,
        refetchOnMountOrArgChange: true
      }
    );

  const searchResults = useMemo(() => {
    if (trimmedText.length === 0) return [];
    else return searchData;
  }, [searchData, trimmedText]);

  const finalData = useMemo(() => {
    if (originalFilteredData?.length === 0 && trimmedText?.length > 0) {
      return searchResults;
    } else {
      return originalFilteredData;
    }
  }, [originalFilteredData, trimmedText, searchResults]);

  const finalDataFiltered = useMemo(() => {
    let data = finalData;
    if (selectedStates?.length > 0) {
      data = data?.filter((item) => {
        return selectedStates.includes(item?.patient?.address?.state);
      });
    }

    if (lastContactRange?.length === 2) {
      data = data?.filter((item) => {
        const latestContactDate = DateTime.fromSQL(item.latestContactCreatedAt);

        return (
          latestContactDate.isValid &&
          lastContactRange[0] < latestContactDate &&
          latestContactDate < lastContactRange[1]
        );
      });
    }

    return data;
  }, [finalData, selectedStates, lastContactRange]);

  const resetDisabled = useMemo(() => {
    return (
      selectedStates.length === 0 &&
      searchText === "" &&
      lastContactRange === undefined
    );
  }, [selectedStates, searchText, lastContactRange]);

  const handleReset = () => {
    setSelectedStates([]);
    setSearchText("");
    setLastContactRange(undefined);
  };

  const filteredTableColumns = useMemo(() => {
    if (tabIndex !== TabPanelIndex.requestsToCancel) {
      return tableColumns.filter(
        ({ name }) =>
          !(
            name === "latestAttritionReasonCategory" ||
            name === "latestAttritionReasonSubCategory" ||
            name === "latestAttritionReasonNotes"
          )
      );
    } else {
      const columnsCopy = [...tableColumns];
      const index = columnsCopy.findIndex((item) => item.name === "date");
      if (index >= 0)
        columnsCopy[index] = {
          name: "date",
          id: "inactive_date",
          accessor: "patient_inactivity.status_effective_date",
          header: "Cancellation Request Date",
          sortingFn: "dateTimeSortingISO",
          size: 200
        };

      return columnsCopy;
    }
  }, [tableColumns, tabIndex]);

  return (
    <TableComponentContainer backgroundColor="transparent" hideBorder>
      <Tabs
        value={tabIndex}
        onChange={handleTabSelect}
        aria-label="Retention Tabs"
      >
        <Tab
          label={"Inactive"}
          style={{ fontWeight: "600" }}
          value={TabPanelIndex.inactive}
          onClick={() => navigate(pathname + "/inactive")}
        />
        <Tab
          label={"Never Engaged"}
          style={{ fontWeight: "600" }}
          value={TabPanelIndex.neverEngaged}
          onClick={() => navigate(pathname + "/never-engaged")}
        />
        <Tab
          label={"Requests to Cancel"}
          style={{ fontWeight: "600" }}
          value={TabPanelIndex.requestsToCancel}
          onClick={() => navigate(pathname + "/requests-to-cancel")}
        />
      </Tabs>

      <br />

      <Row>
        <FilterAltOutlined color="primary" />
        <Typography variant="h6" color="primary">
          Filter by
        </Typography>

        {licensedStatesData && (
          <TextField
            select
            label={"State"}
            sx={{ width: 200, backgroundColor: "white" }}
            id={"State"}
            defaultValue={selectedStates.join(",")}
            slotProps={{
              select: {
                variant: "outlined",
                multiple: true,
                value: selectedStates,
                onChange: (event) => {
                  setSelectedStates(event.target.value as string[]);
                }
              }
            }}
          >
            {licensedStatesData.map((value) => (
              <MenuItem key={value.state_id} value={value.state_id}>
                {value.state_id}
              </MenuItem>
            ))}
          </TextField>
        )}

        <CustomDatePicker
          onClick={() => setDateRangePicking("last_contact")}
          label="Last Contact"
          value={
            lastContactRange?.length > 1
              ? lastContactRange[0].toFormat(DATE_FORMAT) +
                " - " +
                lastContactRange[1].toFormat(DATE_FORMAT)
              : null
          }
        />

        <Button
          startIcon={<LoopOutlined />}
          onClick={handleReset}
          disabled={resetDisabled}
        >
          Reset
        </Button>

        <StyledInput
          fullWidth
          debounceTimeout={300}
          value={searchText}
          onChange={handleText}
          type="text"
          placeholder="Search members"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="end"
                sx={{ backgroundColor: "transparent" }}
              >
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ backgroundColor: "transparent" }}
              >
                <StyledSpinner loading={isSearchFetching.toString()} />
              </InputAdornment>
            )
          }}
        />
      </Row>

      {isFetching && <LoadingFallback delay={500} count={10} />}
      {!isFetching && finalDataFiltered && finalDataFiltered?.length >= 0 && (
        <Table
          tableHeader={isDataSummarized ? undefined : tableHeader}
          tableColumns={filteredTableColumns}
          tableProps={{
            roleConfig,
            setSelectedPatientIdCallback: setSelectedPatientId,
            setModalStateCallback,
            setProviderModalStateCallback,
            openContactAttemptModalHandler,
            openContactAttemptDetailsModalHandler: (
              contactAttempts: MemberRetentionActionsType[]
            ) => setSelectedRetentionActions(contactAttempts),
            externalLink
          }}
          initialSortingState={[
            {
              id: "inactive_date",
              desc: true
            }
          ]}
          data={finalDataFiltered}
          estimateRowSize={(index: number) => {
            if (tabIndex !== TabPanelIndex.requestsToCancel) return 65;

            const item = finalDataFiltered[index];

            const filtered = item?.latest_attrition_reasons?.filter(
              (r: { question: string }) => r.question === "Notes"
            );
            const value = filtered?.length > 0 ? filtered[0]?.answer : "N/A";

            const textLines = getTextWidth(value, undefined) / 168;

            return 50 + 15 * textLines;
          }}
        />
      )}

      {isError && <ErrorComponent error={error} />}

      {hasActions !== undefined && (
        <>
          <AssignNursePatientModal
            key={`assignnurse_${selectedPatientId}`}
            modalOpen={assignNurseModalOpen}
            setModalStateCallback={setModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
          <AssignProviderPatientModal
            key={`assignprovider_${selectedPatientId}`}
            modalOpen={providerModalOpen}
            setModalStateCallback={setProviderModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
        </>
      )}

      <ContactAttemptModal
        isOpen={contactAttemptSendModalOpen}
        onRequestClose={onContactAttemptClose}
        selectedPatientId={selectedPatientId}
        handleOpenSurveyModal={() => setSurveyModalOpen(true)}
      />

      <SurveyModal
        isOpen={surveyModalOpen}
        onRequestClose={() => setSurveyModalOpen(false)}
        title={"Cancellation Reasons"}
        patient={selectedPatient}
      />

      <ContactAttemptDetailsModal
        retentionActions={selectedRetentionActions}
        onRequestClose={() => setSelectedRetentionActions(undefined)}
        memberId={selectedPatientId}
        key={`contactModal${selectedPatientId}`}
      />

      <DateRangePickerModal
        isVisible={dateRangePicking !== undefined}
        onCancel={() => setDateRangePicking(undefined)}
        onConfirm={(start, end) => {
          if (dateRangePicking === "last_contact") {
            setLastContactRange([start.startOf("day"), end.endOf("day")]);
          }
          setDateRangePicking(undefined);
        }}
      />
    </TableComponentContainer>
  );
};

export default InactivePatientList;
