import { Box, Button, Typography } from "@mui/material";
import {
  createRef,
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import { Flexbox } from "../../styling/NewStyleComponents";
import "./ScheduleToday.css";
import { blue, error, gray, success, warning } from "../../styling/colors";
import { ChevronLeft, ChevronRight, InfoOutlined } from "@mui/icons-material";
import { CustomTooltip, TurqoiseButton } from "../../styling/StyleComponents";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import replace from "lodash.replace";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  CAREFLOW_PATH,
  MEMBERS_OVERVIEW_PATH
} from "../../routes/RouteComponents";
import { RootState, useAppDispatch } from "common/redux";
import { callPatientModalSelector, getTextWidth } from "../../helpers/helpers";
import styled from "@emotion/styled";
import ErrorComponent from "../ErrorComponent";
import { useCreateVisitMutation } from "common/services/VisitsService";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import {
  formatName,
  getStatusOrDispositionFromMultipleVisits,
  isFalsy
} from "common/helpers/helpers";
import {
  minusBusinessDays,
  plusBusinessDays
} from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import { useSelector } from "react-redux";
import RolesEnum, {
  canEditDeleteTimeOff,
  canEditProviderBlockedTime,
  canHaveProviderBlockedTime,
  canHaveTimeOff,
  hasProviderRole
} from "common/enums/RolesEnum";
import NewAddTimeOffModal from "../../pages/AllUserList/NursesSchedules/NewAddTimeOffModal";
import { resetPtoState, setCarerTimezone } from "common/redux/PtoSlice";
import EditDeleteMenu from "../ReassignAppointments/EditDeleteMenu";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";
import VisitTypeModal from "../../pages/AllUserList/NursesSchedules/VisitTypeModal";
import StartCareFlow from "./StartCareFlow";
import {
  dayHeaderFormat,
  plugins,
  slotDuration,
  slotLabelFormat,
  slotLabelInterval,
  viewClassNames
} from "./FullCalendarConsts";

import UserScheduleType from "common/types/UserScheduleType";
import ProviderBlockTimeModal from "../../pages/AllUserList/NursesSchedules/ProviderBlockTimeModal";
import { SentryHelper_captureException } from "common/helpers/SentryHelper";
import {
  calculateTimeSlotsForProviderBlock,
  isNurseAppointment,
  isProviderBlockAppointment,
  isTimeOffAppointment
} from "common/helpers/CalendarHelper";
import { AttendeeType } from "common/types/Calendaring/AttendeeType";
import CalendarRecurringEventInfoResponseType from "common/types/Calendaring/CalendarRecurringEventInfoResponseType";
import FeatureFlags from "common/config/FeatureFlags";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledButton = styled(Button)`
  background: white;
  padding: 5px;
  &:hover: {
    backgroundColor: ${gray[25]}
    pointer: cursor;
  }
  lineHeight: 1.3;
`;

function parseOccupiedTimeslots(attendees, key) {
  let finalEventsInSlot = [];
  const firstOccupiedTimeslotIndex = attendees.findIndex(
    (item) => item?.occupiedTimeSlotIndices?.length > 0
  );
  // if we have some occupied time slots due to provider blocks, we need to transform the data
  if (firstOccupiedTimeslotIndex > -1) {
    const occupiedTimeSlotMap = {
      0: undefined,
      1: undefined,
      2: undefined
    };

    const unMappedItems = [];

    attendees.forEach((item) => {
      if (item?.occupiedTimeSlotIndices?.length >= 1) {
        item?.occupiedTimeSlotIndices?.forEach((i, idx) => {
          if (idx === 0) {
            occupiedTimeSlotMap[i] = item;
          } else {
            // if it is a second or third occupied slot, add the filler property
            const modifiedItem = { ...item, isFillerItem: true };
            occupiedTimeSlotMap[i] = modifiedItem;
          }
        });
      } else {
        unMappedItems.push(item);
      }
    });

    unMappedItems.forEach((item) => {
      if (isFalsy(occupiedTimeSlotMap[0])) {
        occupiedTimeSlotMap[0] = item;
      } else if (isFalsy(occupiedTimeSlotMap[1])) {
        occupiedTimeSlotMap[1] = item;
      } else if (isFalsy(occupiedTimeSlotMap[2])) {
        occupiedTimeSlotMap[2] = item;
      }
    });

    if (attendees?.length > 3) {
      SentryHelper_captureException({
        event: `${key} slot is overbooked for ${JSON.stringify(attendees?.[0]?.visitsRequest)}`
      });
    }

    if (!isFalsy(occupiedTimeSlotMap[0])) {
      finalEventsInSlot.push(occupiedTimeSlotMap[0]);
    } else {
      // push a filler item to the slot
      finalEventsInSlot.push({
        ...attendees?.[firstOccupiedTimeslotIndex],
        isFillerItem: true
      });
    }

    if (!isFalsy(occupiedTimeSlotMap[1])) {
      finalEventsInSlot.push(occupiedTimeSlotMap[1]);
    } else {
      // push a filler item to the slot
      finalEventsInSlot.push({
        ...attendees?.[firstOccupiedTimeslotIndex],
        isFillerItem: true
      });
    }

    if (!isFalsy(occupiedTimeSlotMap[2])) {
      finalEventsInSlot.push(occupiedTimeSlotMap[2]);
    } else {
      // push a filler item to the slot
      finalEventsInSlot.push({
        ...attendees?.[firstOccupiedTimeslotIndex],
        isFillerItem: true
      });
    }
  } else {
    finalEventsInSlot = attendees;
  }
  return finalEventsInSlot;
}

function calculateTextWidth(containerWidth: number, isTwoColumn: boolean) {
  return (
    // multiply container width by 0.5 if this is two columns
    (isTwoColumn ? 0.5 : 1) * (containerWidth ?? 0) -
    // width of Call Button
    64 -
    // padding on both sides
    24 -
    // padding in middle
    12
  );
}

function getEventColor(event) {
  // tbd this condition should come from merged data with visits api and check for the call outcome
  if (event.title === "Meeting 1") {
    return {
      backgroundColor: error[50],
      borderColor: error[500]
    };
  } else if (event.extendedProps.isPto) {
    return {
      backgroundColor: warning[50],
      borderColor: warning[500]
    };
  } else if (event.title === "Meeting 3") {
    return {
      backgroundColor: success[50],
      borderColor: success[500]
    };
  } else {
    return {
      backgroundColor: blue[50],
      borderColor: blue[500]
    };
  }
}

function transformDataToCalendarEvents(
  data,
  carerScheduleType,
  carerTimezone = "local"
) {
  const events = [];
  const eventTimeslotsMap = {};
  const ptoTimeslotsMap = {};

  data.forEach((event) => {
    if (isTimeOffAppointment(event.appointment_type)) {
      const eventStartDate = DateTime.fromISO(event.startdate);
      const eventEndDate = DateTime.fromISO(event.enddate);

      let currentDateToCheck = eventStartDate;

      while (currentDateToCheck < eventEndDate) {
        const timeSlotDate = currentDateToCheck.toISODate();
        if (!ptoTimeslotsMap[timeSlotDate]) {
          ptoTimeslotsMap[timeSlotDate] = [event];
        } else {
          ptoTimeslotsMap[timeSlotDate].push(event);
        }
        currentDateToCheck = currentDateToCheck.plus({ days: 1 });
      }
    }

    const eventStartDate = DateTime.fromISO(event.startdate);
    const eventEndDate = DateTime.fromISO(event.enddate);

    const slotsMatchingThisAppointment = [];
    let timeSlot = `${eventStartDate.setZone("utc").toISO()}-${eventEndDate.setZone("utc").toISO()}`;

    if (isProviderBlockAppointment(event.appointment_type)) {
      const slotsMatching = calculateTimeSlotsForProviderBlock(
        event?.startdate,
        event?.enddate,
        carerScheduleType
      );
      // Provider Block events could span multiple time slots
      slotsMatching?.forEach((slot) => {
        slotsMatchingThisAppointment.push(slot);
      });
    } else {
      // regular events should only span one time slot
      slotsMatchingThisAppointment.push({
        timeSlotForThisHour: timeSlot,
        startTime: eventStartDate.toISO(),
        endTime: eventEndDate.toISO()
      });
    }

    slotsMatchingThisAppointment?.forEach((slotObject) => {
      const {
        timeSlotForThisHour,
        timeslotStartDateObject,
        timeslotEndDateObject,
        occupiedTimeSlotIndices
      } = slotObject;

      if (!eventTimeslotsMap[timeSlotForThisHour]) {
        const modifiedEvent = {
          ...event,
          timeslotStart: timeslotStartDateObject,
          timeslotEnd: timeslotEndDateObject,
          occupiedTimeSlotIndices
        };
        eventTimeslotsMap[timeSlotForThisHour] = [modifiedEvent];
      } else {
        const modifiedEvent = { ...event, occupiedTimeSlotIndices };
        eventTimeslotsMap[timeSlotForThisHour].push(modifiedEvent);
      }
    });
  });

  const timeSlotKeys = Object.keys(eventTimeslotsMap);
  timeSlotKeys.forEach((key) => {
    const eventsInSlot = eventTimeslotsMap[key];

    const firstEventInSlot = eventsInSlot[0];
    let timeslotStartString = firstEventInSlot.startdate;
    let timeslotEndString = firstEventInSlot.enddate;
    const eventstartdate = DateTime.fromISO(firstEventInSlot.startdate).setZone(
      carerTimezone
    );
    const eventenddate = DateTime.fromISO(firstEventInSlot.enddate).setZone(
      carerTimezone
    );

    let timeSlot = `${eventstartdate.toFormat("h:mm a")}-${eventenddate.toFormat(
      "h:mm a ZZZZ"
    )}`;

    const timeSlotDate = eventstartdate.toISODate();

    // if there are no pto events in this timeslot, add the event to the calendar
    if (!ptoTimeslotsMap[timeSlotDate]) {
      const fullCalendarEventsInSlot = [];

      eventsInSlot.forEach((event) => {
        fullCalendarEventsInSlot.push({
          attendee: [...event.attendees],
          appointmentType: event.appointment_type,
          visitsRequest: {
            calendar_event_start: event.startdate,
            calendar_event_end: event.enddate,
            staff_id: event.staff.id,
            patient_id: event.attendees?.[0]?.attendee_id,
            // change to event_id with this ticket ENG-4654
            calendar_id: event.event_id
          },
          recurring: event?.recurring,
          recurrenceData: event?.recurrenceData,
          eventInfo: event,
          visits: event.visits,
          isProviderBlock: isProviderBlockAppointment(event.appointment_type),
          occupiedTimeSlotIndices: event?.occupiedTimeSlotIndices
        });
      });

      fullCalendarEventsInSlot.sort((a, b) => {
        // sort alphabetically by first name
        return a?.attendee?.[0]?.first?.localeCompare(b?.attendee?.[0]?.first);
      });

      const finalEventsInSlot = parseOccupiedTimeslots(
        fullCalendarEventsInSlot,
        key
      );

      let modifiedTimeslot;

      if (
        eventsInSlot?.[0]?.timeslotStart?.isValid &&
        eventsInSlot?.[0]?.timeslotEnd?.isValid
      ) {
        modifiedTimeslot = `${eventsInSlot?.[0]?.timeslotStart
          .setZone(carerTimezone)
          .toFormat("h:mm a")} - ${eventsInSlot?.[0]?.timeslotEnd
          .setZone(carerTimezone)
          .toFormat("h:mm a ZZZZ")}`;

        timeslotStartString = eventsInSlot?.[0]?.timeslotStart.toISO();
        timeslotEndString = eventsInSlot?.[0]?.timeslotEnd.toISO();
      }
      const transformedEvent = {
        title: timeSlot,
        start: timeslotStartString,
        end: timeslotEndString,
        extendedProps: {
          type: "appointment_block",
          timeSlot: modifiedTimeslot ?? timeSlot,
          eventsInSlot: finalEventsInSlot,
          isPto: false,
          timeSlotDate
        }
      };

      events.push(transformedEvent);
    } else {
      // add pto event
      const finalEventsInSlot = [];
      eventsInSlot?.forEach((event) => {
        if (isTimeOffAppointment(event.appointment_type)) {
          finalEventsInSlot.push({
            attendee: [...event.attendees],
            appointmentType: event.appointment_type,
            visitsRequest: {
              calendar_event_start: timeslotStartString,
              calendar_event_end: timeslotEndString,
              staff_id: event.staff.id,
              patient_id: event.attendees?.[0]?.attendee_id,
              // change to event_id with this ticket ENG-4654
              calendar_id: event.event_id
            },
            visits: event.visits
          });
        }
      });

      if (finalEventsInSlot.length > 0) {
        const firstEventInSlot = finalEventsInSlot?.[0];
        const transformedEvent = {
          title: timeSlot,
          start: timeslotStartString,
          end: timeslotEndString,
          extendedProps: {
            type: "appointment_block",
            timeSlot,
            eventsInSlot: finalEventsInSlot,
            isPto: true,
            timeSlotDate,
            visitsRequest: {
              calendar_event_start: timeslotStartString,
              calendar_event_end: timeslotEndString,
              staff_id: firstEventInSlot?.visitsRequest?.staff_id,
              patient_id: firstEventInSlot?.visitsRequest?.patient_id,
              calendar_id: firstEventInSlot?.visitsRequest?.calendar_id
            }
          }
        };
        events.push(transformedEvent);
      }
    }
  });
  return events;
}

function NewScheduleToday({
  carerRoles,
  calendarEventsData,
  startdate,
  enddate,
  carerTimezone,
  carerError,
  currentUserId,
  refetchCalendarVisits,
  selectedCarerId,
  isDashboard,
  carerScheduleType,
  carerName,
  recurringEventsWeekdaysMap
}: Readonly<{
  carerRoles: RolesEnum[];
  selectedCarerId: string;
  calendarEventsData: CalendarEventResponseType[];
  startdate: DateTime;
  enddate: DateTime;
  carerTimezone?: string;
  carerError?: unknown;
  currentUserId: string;
  isDashboard?: boolean;
  carerScheduleType?: UserScheduleType;
  carerName: string;
  recurringEventsWeekdaysMap: Record<
    string,
    CalendarRecurringEventInfoResponseType[]
  >;
  refetchCalendarVisits: () => void;
}>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [rerender, setRerender] = useState({});
  const [events, setEvents] = useState([]);
  const [isProcessingData, setisProcessingData] = useState<boolean>(false);

  const [isEditTimeOffModalOpen, setIsEditTimeOffModalOpen] =
    useState<boolean>(false);
  const [isAddTimeOffModalOpen, setIsAddTimeOffModalOpen] =
    useState<boolean>(false);
  const [visitModalAttendee, setVisitModalAttendee] = useState<{
    attendee: AttendeeType;
    visitsRequest: any;
  }>(null);

  const [
    isEditProviderBlockTimeModalOpen,
    setIsEditProviderBlockTimeModalOpen
  ] = useState<boolean>(false);
  const [isAddProviderBlockTimeModalOpen, setIsAddProviderBlockTimeModalOpen] =
    useState<boolean>(false);

  const setIsEditTimeOffModalOpenCallback = useCallback(
    (val) => {
      if (val === true) {
        dispatch(setCarerTimezone(carerTimezone));
        setIsEditTimeOffModalOpen(val);
      } else {
        setIsEditTimeOffModalOpen(val);
      }
    },
    [setIsEditTimeOffModalOpen, carerTimezone]
  );

  const setIsEditProviderBlockTimeModalOpenCallback = useCallback(
    (val) => {
      if (val === true) {
        dispatch(setCarerTimezone(carerTimezone));
        setIsEditProviderBlockTimeModalOpen(val);
      } else {
        setIsEditProviderBlockTimeModalOpen(val);
      }
    },
    [setIsEditProviderBlockTimeModalOpen, carerTimezone]
  );

  const startOfDay = useMemo(() => DateTime.now().startOf("day"), []);

  const [isCurrentDay, setIsCurrentDay] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<DateTime>(startOfDay);

  const scrollTime = useMemo(() => {
    return DateTime.now()
      .setZone(carerTimezone)
      .endOf("minute")
      .minus({ minutes: 30 })
      .toFormat("hh:mm:ss");
  }, [carerTimezone]);

  const slotMinTime = useMemo(() => {
    const min = DateTime.now()
      .startOf("minute")
      .setZone(carerScheduleType?.timezone)
      .set({
        hour: carerScheduleType?.starting_hour,
        minute: carerScheduleType?.starting_minutes
      })
      .setZone(carerTimezone);

    if (!carerScheduleType || !hasProviderRole(carerRoles) || !min?.isValid) {
      return "08:30:00";
    }

    return min.toFormat("HH:mm:ss");
  }, [carerScheduleType, carerTimezone]);

  const slotMaxTime = useMemo(() => {
    const max = DateTime.now()
      .startOf("minute")
      .setZone(carerScheduleType?.timezone)
      .set({
        hour: carerScheduleType?.ending_hour,
        minute: carerScheduleType?.ending_minutes
      })
      .setZone(carerTimezone);

    if (!carerScheduleType || !hasProviderRole(carerRoles) || !max?.isValid)
      return "17:00:00";

    return max.toFormat("HH:mm:ss");
  }, [carerScheduleType, carerTimezone]);

  const eventDidMount = useCallback(
    (arg) => {
      const eventColor = getEventColor(arg.event);
      arg.el.style.backgroundColor = eventColor.backgroundColor;
      arg.el.style.borderColor = eventColor.borderColor;
    },
    [events]
  );

  const eventIndicatorCallback = useCallback(
    (arg) =>
      eventIndicator(arg, dispatch, currentRole, setIsEditTimeOffModalOpen),
    [events]
  );

  useEffect(() => {
    dispatch(setCarerTimezone(carerTimezone));
    return () => {
      dispatch(resetPtoState(null));
    };
  }, [carerTimezone]);

  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!calendarEventsData) return;
    setisProcessingData(true);

    // add a delay to give the new data time to load and avoid race conditions
    timerRef.current = setTimeout(() => {
      const transformedEvents = transformDataToCalendarEvents(
        calendarEventsData,
        carerScheduleType,
        carerTimezone
      );
      setEvents(transformedEvents);
      setisProcessingData(false);
    }, 200);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [calendarEventsData, carerTimezone, carerScheduleType]);

  const calendarRef: LegacyRef<FullCalendar> = createRef();

  function prev() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();

    const current = calendarApi.getDate();

    if (!DateTime.now().hasSame(current, "day")) {
      calendarApi.scrollToTime("08:00:00");
    }

    setRerender({});
  }

  function next() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();

    const current = calendarApi.getDate();

    if (!DateTime.now().hasSame(current, "day")) {
      calendarApi.scrollToTime("08:00:00");
    }

    setRerender({});
  }

  function today() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setRerender({});
  }

  useEffect(() => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi();
      const calendarDate = calendarApi.getDate();
      const calendarDateFormatted = DateTime.fromJSDate(calendarDate)
        .toUTC()
        .startOf("day");
      const currentDate = DateTime.now().startOf("day");
      if (calendarDateFormatted.hasSame(currentDate, "day")) {
        if (!isCurrentDay) setIsCurrentDay(true);
      } else if (isCurrentDay) setIsCurrentDay(false);

      if (!calendarDateFormatted.hasSame(currentDay, "day")) {
        setCurrentDay(calendarDateFormatted);
      }
    }
  }, [calendarRef, rerender]);

  const [
    createVisitMutation,
    {
      error: createVisitError,
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      data: createVisitResult,
      reset: resetCreateVisit
    }
  ] = useCreateVisitMutation();

  // if visit is created with care flow, navigate to the care flow
  useEffect(() => {
    if (createVisitIsSuccess && createVisitResult.care_flow) {
      let careFlowLink = replace(
        CAREFLOW_PATH,
        ":visitId",
        createVisitResult?.visit_id
      );

      navigate(careFlowLink);
    }
  }, [createVisitIsSuccess]);

  const { data: currentVisit } = useGetCurrentVisit({
    staffId: user?.user_id
  });

  // version of the eventIndicator with 4 events all in the same calendar event
  function eventIndicator(
    arg,
    dispatch,
    currentRole,
    setIsEditTimeOffModalOpen
  ) {
    const now = DateTime.now().startOf("minute");

    const eventCount = arg.event?.extendedProps?.eventsInSlot?.length;
    const eventStart = arg?.event?._instance?.range?.start;
    const isSameDay = now.hasSame(eventStart, "day");

    const containerWidth =
      // @ts-ignore
      document?.getElementsByClassName("fc-event-main")?.[0]?.offsetWidth;

    let approxTextContainerWidth = calculateTextWidth(
      containerWidth,
      eventCount > 4
    );

    if (arg?.event?.extendedProps?.isPto) {
      const ptoEvent = arg?.event?.extendedProps?.eventsInSlot?.[0];
      const text = canEditDeleteTimeOff(currentRole)
        ? // show the reason for managers
          `TIME OFF - ${formatName(ptoEvent?.appointmentType)}`
        : // show "OUT OF OFFICE" for nurses
          "OUT OF OFFICE";
      return (
        <Flexbox
          flexDirection="column"
          width="100%"
          height="95%"
          margin="2px 0"
        >
          <Flexbox
            textAlign="center"
            width="calc(100% - 20px)"
            margin="10px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight={600} color={"warning"}>
              {text}
            </Typography>
            {canEditDeleteTimeOff(currentRole) && (
              <EditDeleteMenu
                id={ptoEvent?.visitsRequest?.calendar_id}
                staffId={ptoEvent?.visitsRequest?.staff_id}
                event={ptoEvent}
                setIsEditTimeOffModalOpen={setIsEditTimeOffModalOpenCallback}
                refetchCalendarVisits={refetchCalendarVisits}
                isPto={true}
              />
            )}
          </Flexbox>
        </Flexbox>
      );
    }

    return (
      <Flexbox flexDirection="column" width="100%" height="95%" margin="2px 0">
        <Box textAlign="center" width="100%">
          <Typography variant="body1" fontWeight={600} color="text.primary">
            {arg.event.extendedProps.timeSlot}
          </Typography>
        </Box>
        <Flexbox
          flexDirection={"column"}
          justifyContent="space-between"
          height="85%"
          width="100%"
          padding="0 12px"
          rowGap="2px"
          columnGap={eventCount > 4 && "12px"}
          flexWrap="wrap"
        >
          {arg?.event?.extendedProps?.eventsInSlot?.length > 0 &&
            arg?.event?.extendedProps?.eventsInSlot?.map((item) => {
              const visitsRequest = item?.visitsRequest;
              const visits = item?.visits;
              const visitStatus =
                visits.length > 0
                  ? getStatusOrDispositionFromMultipleVisits(visits)
                  : null;

              const attendee = item?.attendee?.[0];
              const memberId = attendee.attendee_id;
              const link = replace(
                MEMBERS_OVERVIEW_PATH,
                ":memberId",
                memberId
              );

              let attendeeName = `${attendee.first} ${attendee.last}`;
              let recommendedEncounterText = "";
              const recommendedEncounterMinutes =
                attendee?.recommended_encounter_minutes;
              const recommendedTimeColor = "#7a2b20";

              const showRecommendedEncounterMinutes =
                recommendedEncounterMinutes &&
                visitStatus !== VisitDispositionEnum.COMPLETED &&
                visitStatus !== VisitDispositionEnum.NO_SHOW &&
                visitStatus !== VisitDispositionEnum.TN_OOO &&
                visitStatus !== VisitDispositionEnum.NO_CALL;

              if (visitStatus) {
                attendeeName = `${attendeeName} (${formatName(visitStatus)})`;
              }

              if (showRecommendedEncounterMinutes) {
                attendeeName = `${attendeeName} (`;
                recommendedEncounterText = `TARGET MINS: ${recommendedEncounterMinutes}`;
              }

              const approxTextWidth = getTextWidth(
                `${attendeeName}${showRecommendedEncounterMinutes ? recommendedEncounterText : ""})`,
                "14px Inter"
              );

              if (item.isProviderBlock) {
                const providerBlockEvent =
                  arg.event?.extendedProps?.eventsInSlot?.find(
                    (it) =>
                      it?.visitsRequest?.calendar_id ===
                      item?.visitsRequest?.calendar_id
                  );
                if (item?.isFillerItem) {
                  const uuid = uuidv4();
                  // return an empty filler item
                  return (
                    <Box
                      key={`${visitsRequest.calendar_id} filler ${uuid}`}
                      height="31px"
                      width="100%"
                    >
                      &nbsp;
                    </Box>
                  );
                }
                return (
                  <Flexbox
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={visitsRequest.calendar_id}
                    width="100%"
                    // one line is 30.2px
                    maxHeight="31px"
                  >
                    <Flexbox
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      alignItems="center"
                      width="100%"
                    >
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        color="text.primary"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        width="100%"
                        height="100%"
                      >
                        <Flexbox
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Typography
                            variant="body1"
                            fontWeight={600}
                            color={"text.primary"}
                          >
                            {`BLOCKED - ${formatName(item.appointmentType)} from ${DateTime.fromISO(item.visitsRequest.calendar_event_start).setZone(carerTimezone).toFormat("h:mm a")} to ${DateTime.fromISO(item.visitsRequest.calendar_event_end).setZone(carerTimezone).toFormat("h:mm a ZZZZ")}`}
                          </Typography>
                          {/* // tbd change this to a different variable name for providers */}
                          {canEditProviderBlockedTime(currentRole) && (
                            <EditDeleteMenu
                              id={item.visitsRequest.calendar_id}
                              staffId={item.visitsRequest.staff_id}
                              recurring={item?.recurring}
                              event={providerBlockEvent}
                              setIsEditTimeOffModalOpen={
                                setIsEditProviderBlockTimeModalOpenCallback
                              }
                              refetchCalendarVisits={refetchCalendarVisits}
                              isPto={false}
                            />
                          )}
                        </Flexbox>
                      </Typography>
                    </Flexbox>
                  </Flexbox>
                );
              }

              return (
                <Flexbox
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={visitsRequest.calendar_id}
                  width={
                    eventCount > 4
                      ? // 12px is the gap between the two columns
                        "calc(50% - 6px)"
                      : "100%"
                  }
                  // one line is 30.2px
                  maxHeight="31px"
                >
                  <Flexbox
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color="text.primary"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      width="100%"
                      height="100%"
                    >
                      <StyledLink to={link} style={{ display: "block" }}>
                        <Typography
                          sx={{ cursor: "pointer" }}
                          variant="body1"
                          fontWeight="700"
                          color="primary.main"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          width="100%"
                          height="100%"
                        >
                          {/* TBD Comment this back in when we want to support telehealth nurse setup */}
                          {/* {appointmentType}:  */}
                          {attendeeName}
                          {showRecommendedEncounterMinutes && (
                            <>
                              <span style={{ color: recommendedTimeColor }}>
                                {recommendedEncounterText}
                              </span>
                              &#41;
                            </>
                          )}
                        </Typography>
                      </StyledLink>
                    </Typography>
                  </Flexbox>
                  <Flexbox gap="4px" alignItems="center">
                    {approxTextWidth > approxTextContainerWidth &&
                      isNurseAppointment(item?.appointmentType) &&
                      showRecommendedEncounterMinutes && (
                        <CustomTooltip
                          title={`${attendeeName}${showRecommendedEncounterMinutes ? recommendedEncounterText + ")" : ""}`}
                          placement="top-start"
                        >
                          <InfoOutlined color="primary" />
                        </CustomTooltip>
                      )}

                    <Flexbox gap="4px">
                      {isNurseAppointment(item?.appointmentType) && (
                        <StartCareFlow
                          isSameDay={isSameDay}
                          visitsRequest={visitsRequest}
                        />
                      )}
                      {isSameDay && !FeatureFlags(user?.user_id).CARE_FLOWS && (
                        <StyledButton
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            // TBD remove check once backend handles the check
                            if (visits.length === 0) {
                              createVisitMutation({
                                staff_id: currentUserId,
                                patient_id: visitsRequest.patient_id,
                                body: {
                                  ...visitsRequest,
                                  staff_id: currentUserId,
                                  motivation_reason:
                                    VisitMotivationTypesEnum.APPOINTMENT
                                }
                              });
                            }

                            callPatientModalSelector(dispatch, attendee, () =>
                              navigate(link)
                            );
                          }}
                        >
                          Call
                        </StyledButton>
                      )}
                      {/* <Button
                sx={{
                  background: "white",
                  padding: "5px",
                  "&:hover": {
                    backgroundColor: gray[25],
                    pointer: "cursor",
                  },
                }}
                variant="outlined"
                onClick={() => {
                  alert("update placeholder");
                }}
              >
                Update
              </Button> */}
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              );
            })}
        </Flexbox>
      </Flexbox>
    );
  }

  const getCarerTime = useCallback(() => {
    return DateTime.now().startOf("minute").setZone(carerTimezone);
  }, [carerTimezone]);

  const defaultCarerTime = useMemo(() => {
    return getCarerTime();
  }, []);

  const [carerTime, setCarerTime] = useState(defaultCarerTime);
  useEffect(() => {
    const newCarerTime = getCarerTime();
    const interval = setInterval(() => setCarerTime(newCarerTime), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const addProviderBlockOnRequestClose = useCallback(
    (refetch: boolean) => {
      if (refetch) {
        refetchCalendarVisits();
      }
      setIsAddProviderBlockTimeModalOpen(false);
      dispatch(resetPtoState(null));
    },
    [refetchCalendarVisits, setIsAddProviderBlockTimeModalOpen]
  );

  const editProviderBlockOnRequestClose = useCallback(
    (refetch: boolean) => {
      if (refetch) {
        refetchCalendarVisits();
      }
      setIsEditProviderBlockTimeModalOpen(false);
      dispatch(resetPtoState(null));
    },
    [refetchCalendarVisits, setIsEditProviderBlockTimeModalOpen]
  );

  return (
    <>
      <Box>
        <Flexbox
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Flexbox justifyContent="space-between" width="100%">
            <Flexbox gap="16px" alignItems="center" mb="18px">
              <Flexbox gap="8px" alignItems="center">
                <Typography variant="h4">Schedule</Typography>
                <CustomTooltip
                  backgroundColor={gray[200]}
                  placement="bottom"
                  title={
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      maxWidth="225px"
                    >
                      Your schedule for the selected date is shown here. Keep in
                      mind that appointments are scheduled in “windows” and
                      there are 4 members assigned to each one hour window.
                    </Typography>
                  }
                >
                  <InfoOutlined color="primary" />
                </CustomTooltip>
              </Flexbox>
              {events && events.length > 0 && (
                <Flexbox gap="8px">
                  <Button
                    variant="outlined"
                    onClick={today}
                    disabled={isCurrentDay}
                  >
                    Today
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={prev}
                    disabled={
                      currentDay.toMillis() <= startdate.toMillis() ||
                      minusBusinessDays(currentDay, 1)
                        .startOf("day")
                        .toMillis() <= startdate.toMillis()
                    }
                  >
                    <ChevronLeft />
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={next}
                    disabled={
                      // disable the next button if clicking "next" will be greater than or equal to the enddate
                      plusBusinessDays(currentDay, 1).endOf("day").toMillis() >=
                      enddate.startOf("day").toMillis()
                    }
                  >
                    <ChevronRight />
                  </Button>
                </Flexbox>
              )}
              {carerTimezone && (
                <Typography variant="caption" color="text.secondary">
                  Local time: <br />
                  {carerTime.toFormat("h:mm a ZZZZ")}
                </Typography>
              )}
              {(carerError || !carerTimezone) && (
                <Typography variant="body1" color="error">
                  Error getting carer timezone
                  <ErrorComponent
                    error={carerError}
                    style={{ fontSize: "14px" }}
                  />
                </Typography>
              )}
            </Flexbox>
            <Flexbox mb={"18px"}>
              {isDashboard && (
                <TurqoiseButton
                  sx={{ width: "135px" }}
                  onClick={() => {
                    navigate(
                      `/staff/staffId/${currentUserId ?? selectedCarerId}/schedule`
                    );
                  }}
                >
                  See Full Schedule
                </TurqoiseButton>
              )}
            </Flexbox>
          </Flexbox>
          <Flexbox gap="8px">
            {canEditDeleteTimeOff(currentRole) &&
              canHaveTimeOff(carerRoles) &&
              !isDashboard && (
                <TurqoiseButton
                  sx={{
                    minWidth: "130px",
                    width: "130px",
                    marginBottom: "18px",
                    // match the height of the other buttons
                    height: "37px"
                  }}
                  onClick={() => {
                    dispatch(setCarerTimezone(carerTimezone));
                    setIsAddTimeOffModalOpen(true);
                  }}
                >
                  Add Time Off
                </TurqoiseButton>
              )}
            {canEditProviderBlockedTime(currentRole) &&
              canHaveProviderBlockedTime(carerRoles) &&
              !isDashboard && (
                <TurqoiseButton
                  sx={{
                    minWidth: "150px",
                    width: "150px",
                    marginBottom: "18px",
                    // match the height of the other buttons
                    height: "37px"
                  }}
                  onClick={() => {
                    dispatch(setCarerTimezone(carerTimezone));
                    setIsAddProviderBlockTimeModalOpen(true);
                  }}
                >
                  Add Blocked Time
                </TurqoiseButton>
              )}
          </Flexbox>
        </Flexbox>
        {events && events.length > 0 && !isProcessingData && (
          <FullCalendar
            ref={calendarRef}
            viewClassNames={viewClassNames}
            plugins={plugins}
            timeZone={carerTimezone ?? "local"}
            dayHeaderFormat={dayHeaderFormat}
            headerToolbar={false}
            initialView="timeGridDay"
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={false}
            weekends={false}
            allDaySlot={false}
            slotEventOverlap={false}
            slotDuration={slotDuration}
            slotLabelInterval={slotLabelInterval}
            slotLabelFormat={slotLabelFormat}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            nowIndicator={true}
            eventContent={eventIndicatorCallback}
            eventDidMount={eventDidMount}
            height={508}
            scrollTime={scrollTime}
            scrollTimeReset={true}
            initialEvents={events}
            // select={this.handleDateSelect}
            // eventClick={this.handleEventClick}
          />
        )}
        {events && events.length === 0 && (
          <Typography variant="body1" color="text.secondary">
            No calendar events found for the next 4 weeks.
          </Typography>
        )}
      </Box>
      <NewAddTimeOffModal
        modalKey={`editTimeOffModal${selectedCarerId}`}
        isEdit={true}
        isVisible={isEditTimeOffModalOpen}
        onRequestClose={(refetch: boolean) => {
          if (refetch) {
            refetchCalendarVisits();
          }
          setIsEditTimeOffModalOpen(false);
        }}
      />
      <NewAddTimeOffModal
        modalKey={`addTimeOffModal${selectedCarerId}`}
        selectedCarerId={selectedCarerId}
        isVisible={isAddTimeOffModalOpen}
        onRequestClose={(refetch: boolean) => {
          if (refetch) {
            refetchCalendarVisits();
          }
          setIsAddTimeOffModalOpen(false);
        }}
      />

      <ProviderBlockTimeModal
        modalKey={`addProviderBlockTimeModal${selectedCarerId}`}
        selectedCarerId={selectedCarerId}
        carerName={carerName}
        isEdit={false}
        isVisible={isAddProviderBlockTimeModalOpen}
        onRequestClose={addProviderBlockOnRequestClose}
        providerScheduleType={carerScheduleType}
        calendarEventsData={calendarEventsData}
        recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
      />
      <ProviderBlockTimeModal
        modalKey={`editProviderBlockTimeModal${selectedCarerId}`}
        isEdit={true}
        selectedCarerId={selectedCarerId}
        carerName={carerName}
        isVisible={isEditProviderBlockTimeModalOpen}
        onRequestClose={editProviderBlockOnRequestClose}
        providerScheduleType={carerScheduleType}
        calendarEventsData={calendarEventsData}
        recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
      />

      {visitModalAttendee && (
        <VisitTypeModal
          attendee={visitModalAttendee?.attendee}
          visitsRequest={visitModalAttendee?.visitsRequest}
          staff_id={currentUserId}
          onRequestClose={() => {
            setVisitModalAttendee(null);
          }}
        />
      )}
    </>
  );
}

export default NewScheduleToday;
