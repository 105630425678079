import RolesEnum from "common/enums/RolesEnum";
import { DocumentIcon } from "../../assets/images/icons";

import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ALL_TEAMS,
  AWAITING_PROVIDER,
  CREATE_TEAM,
  INACTIVE_MEMBERS,
  LOGOUT,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  ORDER_DETAILS,
  STAFF_DETAILS,
  STAFF_MEMBERS,
  TEAM_DETAILS
} from "../RouteComponents";

import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_CREATE_TEAM,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MIGRATE_MEMBER,
  SIDEBAR_ORDERS,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";

const TECHNICAL_SUPPORT = {
  label: "Technical Support",
  routes: [
    {
      path: "/",
      components: [
        ALL_MEMBERS({
          tableColumns: [
            { name: "name" },
            { name: "status" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    {
      path: "/migrate-patient",
      components: [
        {
          type: "migrate-patient"
        }
      ]
    },
    {
      path: "/staff-members",
      components: [STAFF_MEMBERS]
    },
    {
      path: "/create-user",
      components: [
        {
          type: "create-user"
        }
      ]
    },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/awaiting-provider",
      components: [
        AWAITING_PROVIDER({
          tableColumns: [
            { name: "name" },
            { name: "phone" },
            { name: "birthdate" },
            { name: "nurseAssigned" }
          ]
        })
      ]
    },
    { path: "/inactive-members", components: [INACTIVE_MEMBERS(false)] },

    ...MEMBER_CHART(RolesEnum.TECHNICAL_SUPPORT),
    ...MEMBER_CHART_CALENDARING(),
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        },
        ALL_RECENT_ORDERS()
      ]
    },
    {
      path: "/devices/:memberId",
      components: [
        {
          type: "order-devices"
        }
      ]
    },
    ORDER_DETAILS(),
    STAFF_DETAILS,
    CREATE_TEAM,
    ALL_TEAMS({
      types: [TeamTypeEnum.TH_NURSES]
    }),
    TEAM_DETAILS,
    LOGOUT
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.users,
      items: [
        {
          itemName: "Create User",
          itemLink: "/create-user",
          ItemIcon: DocumentIcon
        },
        {
          itemName: "Staff Members",
          itemLink: "/staff-members",
          ItemIcon: DocumentIcon
        },
        SIDEBAR_CREATE_TEAM,
        SIDEBAR_CLINICAL_TEAMS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    SIDEBAR_MIGRATE_MEMBER
  ]
};

export default TECHNICAL_SUPPORT;
