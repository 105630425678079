import EnvVars from "./EnvVars";
import VisitFlowUserIDs from "./VisitFlowUserIDs.json";
import { RootState } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { store } from "../redux";

export interface FeatureFlagsType {
  ARC_OF_DAY: boolean;
  CARE_FLOWS: boolean;
  CARE_FLOWS_REQUIRED_FIELD_CHECK: boolean;
  STRIPS_ON_HAND: boolean;
  PROVIDER_CALENDARING: boolean;
}

const isDev = () => {
  return (
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" &&
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "staging"
  );
};

export const PROVIDER_CALENDARING = () =>
  [
    "dev",
    "staging"
    // "prod"
  ].includes(EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV);

const FeatureFlags = (user_id_param?: string) => {
  const user_id = user_id_param ?? store.getState().auth?.user?.user_id;
  const isIncludedInJSON =
    user_id &&
    VisitFlowUserIDs[EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV].includes(user_id);

  return {
    ARC_OF_DAY: isDev(),
    CARE_FLOWS: isIncludedInJSON,
    CARE_FLOWS_DEVICE_SETUP: isDev() ? true : false, // Turning off until later
    CARE_FLOWS_GOALS_RELATIONSHIP_NOTES_PROFILE: false, // Turning off until later
    CARE_FLOWS_REQUIRED_FIELD_CHECK: false, // Turning off until later based on Product guidance
    STRIPS_ON_HAND: isDev() ? true : false,
    PROVIDER_CALENDARING: PROVIDER_CALENDARING()
  };
};

export const useFeatureFlags = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  return FeatureFlags(user?.user_id);
};

export default FeatureFlags;
