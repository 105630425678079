import { useEffect, useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "common/redux";
import { SentryHelper_captureException } from "common/helpers/SentryHelper";
import { logOut, onAppStart } from "common/redux/AuthSlice";
import { CURRENT_TAB_ID } from "common/redux/VideoCallSlice";

import Layout from "../components/Layout";
import useRouteConfigByRole from "../hooks/useRouteConfigByRole";
import LoginPage from "../pages/Login/LoginPage";
import AthenaRouter from "../pages/AthenaRouter/AthenaRouter";
import NotFound from "../pages/NotFound";

import { EmotionTheme } from "./../styling";
import RouteWrapper from "./RouteWrapper";
import RouteType from "../types/RouteType";
import ReduxModal from "../components/Modal/ReduxModal";
import { parseJSON } from "../helpers/helpers";

import LoadingPage from "../pages/LoadingPage";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import { setSelectedMemberId } from "common/redux/webadmin/LayoutSlice";

const VideoCallComponent = lazy(
  () => import("../components/Twilio/VideoCallComponent")
);

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const ErrorComponent = ({ children }: any) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(error) => {
        SentryHelper_captureException(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const RouterTheme = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, isRefreshingToken } = useSelector(
    (state: RootState) => state.auth
  );

  const { code, tabId } = useSelector((state: RootState) => state.videoCall);

  const routeConfig = useRouteConfigByRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes("patient")) {
      const redirectUri = pathname.replaceAll("patient", "member");
      navigate(redirectUri);
    }
  }, []);

  useEffect(() => {
    // Reset selected member id and timer when navigating away from member page or download member chart
    if (
      !(
        pathname.includes("members/memberId") ||
        pathname.includes("member_chart_share/memberId")
      )
    ) {
      dispatch(setSelectedMemberId(undefined));
    }
  }, [pathname]);

  const tabOpenDate = useMemo(() => DateTime.now(), []);

  function logoutListener(event: any) {
    // @ts-ignore
    const parsed = parseJSON(event.newValue);
    if (parsed?.auth) {
      const parsedAuth = parseJSON(parsed?.auth);
      if (parsedAuth.isLoggedIn === false) {
        // logout all other tabs
        dispatch(logOut(false));
      }
    }
  }

  useEffect(() => {
    // Creates listener for storage change events from other tabs.
    // Logout triggered from another tab
    if (isLoggedIn) {
      window.addEventListener("storage", logoutListener);
    }

    return () => {
      window.removeEventListener("storage", logoutListener);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(onAppStart());
  }, []);

  const routesJSX = useMemo(() => {
    return routeConfig?.routes?.map((route: RouteType<any>, index: number) => {
      if (route === undefined) return;

      const { path, components } = route;
      return (
        <Route
          key={`${path}`}
          path={path}
          element={
            isLoggedIn === true ? (
              <RouteWrapper components={components} />
            ) : (
              <LoginPage />
            )
          }
        />
      );
    });
  }, [routeConfig, isLoggedIn, pathname]);

  const hideSideBar = isLoggedIn && pathname.includes("/video");

  const loadVideoCallComponents =
    isLoggedIn && code != null && tabId === CURRENT_TAB_ID;

  // This prevents the loading to display if the user is already using the app.
  // Loading should show only when the user opens the tab, not after.
  if (tabOpenDate > DateTime.now().minus({ seconds: 2 }) && isRefreshingToken)
    return (
      <DelayedRender delay={500}>
        <LoadingPage />
      </DelayedRender>
    );

  return (
    <EmotionTheme>
      <Layout hideSidebar={hideSideBar}>
        <ErrorComponent>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/athenarouter/:athenaId" element={<AthenaRouter />} />
            {routesJSX && [...routesJSX]}
            {isLoggedIn ? (
              <Route path="*" element={<NotFound />} />
            ) : (
              <Route path="*" element={<LoginPage to="/login" />} />
            )}
          </Routes>
        </ErrorComponent>
      </Layout>
      {loadVideoCallComponents && (
        <Suspense fallback={<LoadingPage />}>
          <VideoCallComponent />
        </Suspense>
      )}

      <ReduxModal />
    </EmotionTheme>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <RouterTheme />
    </BrowserRouter>
  );
};

export default Router;
