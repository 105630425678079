import { useMemo, useState } from "react";
import { MenuItem, Typography } from "@mui/material";

import RolesEnum, { StaffRoles } from "common/enums/RolesEnum";
import { useUpdateUserRoleByUsernameMutation } from "common/services/UserService";
import UserType from "common/types/UserType";

import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../styling/StyleModal";
import { getRoleLabel } from "../../../helpers/helpers";
import { TurqoiseButton, WhiteButton } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";

interface IProps {
  isVisible: boolean;
  onRequestClose: () => void;
  user: UserType;
}

const ChangeRoleModal = ({ isVisible, onRequestClose, user }: IProps) => {
  const [selectedRoles, setSelectedRoles] = useState<RolesEnum[]>(
    user?.user?.roles
  );

  const [
    updateUserRoleByUsernameMutation,
    {
      isLoading: isUpdateRoleLoading,
      error,
      isSuccess: updateRoleSuccess,
      reset: resetUpdateRole
    }
  ] = useUpdateUserRoleByUsernameMutation();

  const handleChangeRoleModalClose = () => {
    resetUpdateRole();
    onRequestClose();
  };

  const roleUpdateButtonDisabled = useMemo(() => {
    if (user?.user?.roles === undefined || selectedRoles === undefined)
      return false;
    if (selectedRoles.length === 0) return true;

    const userRolesString = [user?.user?.roles].sort().toString();
    const selectedRolesString = [...selectedRoles].sort().toString();

    return userRolesString === selectedRolesString;
  }, [selectedRoles, user?.user?.roles]);

  return (
    <StyledModal
      isOpen={isVisible}
      onRequestClose={handleChangeRoleModalClose}
      modalHeight="70vh"
      contentLabel="Change Role"
    >
      <ModalHeader onRequestClose={handleChangeRoleModalClose}>
        Change Role
      </ModalHeader>
      <ModalBody>
        <StyledTextField
          select
          label="Roles"
          slotProps={{
            select: {
              variant: "outlined",
              multiple: true,
              value: selectedRoles || [],
              onChange: (event) => setSelectedRoles(event.target.value)
            }
          }}
        >
          {StaffRoles.map((role) => {
            return (
              <MenuItem key={role} value={role}>
                {getRoleLabel(role as RolesEnum)}
              </MenuItem>
            );
          })}
        </StyledTextField>

        {updateRoleSuccess && (
          <Typography variant="body1" color="green" margin={"10px"}>
            Role has been updated successfully
          </Typography>
        )}

        {error && <ErrorComponent error={error} />}
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton
            onClick={() =>
              updateUserRoleByUsernameMutation({
                email: user.user.email,
                roles: selectedRoles
              })
            }
            loading={isUpdateRoleLoading}
            disabled={roleUpdateButtonDisabled}
          >
            Update Role
          </TurqoiseButton>
          <WhiteButton onClick={handleChangeRoleModalClose}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default ChangeRoleModal;
