import { useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled";
import cloneDeep from "lodash.clonedeep";
import c3 from "c3";
import "c3/c3.css";
import useGetMemberDataSummary from "common/hooks/useGetMemberDataSummary";
import {
  AvgTextContainer,
  ChartHeader,
  ChartHeaderText,
  ChartIconContainer,
  ChartIconSVG,
  ChartSubHeaderText,
  ThreeLinesContainer,
  ThreeLinesSVG,
  getGlucoseColorFromDataIndex,
  transformGlucoseDataToColumns
} from "../../../helpers/components/Charts/ChartHelpers";
import ErrorComponent from "../../../components/ErrorComponent";
import { CustomDataPoint } from "../../../@types/customc3";
import { gray } from "../../../styling/colors";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { DateTime } from "luxon";
import MemberType from "common/types/MemberType";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import ReadingChartPropsType from "common/types/common/ReadingChartPropsType";
import { getFormattedDateTime } from "../../../helpers/helpers";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { Button, Typography } from "@mui/material";
import { canDownloadChart } from "common/enums/RolesEnum";
import { FileDownloadOutlined } from "@mui/icons-material";
import GlucoseTable from "./GlucoseTable";
import ReportEnum from "../../../enums/ReportEnum";
import StorageHelper from "common/helpers/StorageHelper";
import StorageEnum from "common/enums/StorageEnum";
import { Column } from "../../../styling/StyleComponents";
import ChartControls from "./ChartControls";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";

const ChartContainer = styled.div`
  margin: 0px;
`;

const GlucoseChartContainer = styled.div`
  margin: 12px 0;
  border-radius: 4px;
  border: 1px solid ${gray[300]};
  background: #ffffff;
  padding: 18px 8px;
  height: 100%;
  transition: all 0.66s ease-out;

  .glucoseChart {
    height: 450px;
    margin-left: 18px;
  }

  .glucoseChart svg {
    width: calc(100%);
  }

  .glucoseChart .c3-circle {
    r: 3;
  }

  .glucoseChart .c3-circle._expanded_ {
    visibility: visible;
    r: 4;
  }

  .glucoseChart .c3-legend-background {
    stroke: none;
  }

  .glucoseChart .c3-legend-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgba(24, 24, 25, 0.42);
  }

  // dashed lines
  .c3-shapes-BEFORE-BREAKFAST.c3-lines.c3-lines-BEFORE-BREAKFAST {
    stroke-dasharray: 5, 5;
  }
  .c3-shapes-OTHER.c3-lines.c3-lines-OTHER {
    stroke-dasharray: 5, 5;
  }

  // hide lines
  .c3-shapes-BEFORE-BREAKFAST.c3-lines.c3-lines-BEFORE-BREAKFAST > path {
    stroke: none !important;
  }
  .c3-shapes-OTHER.c3-lines.c3-lines-OTHER > path {
    stroke: none !important;
  }

  .c3-axis-x-label {
    font: 15px "Inter";
  }
  .c3-axis-y-label {
    font: 15px "Inter";
  }

  // hide average dots

  .c3-circles-GLUCOSE-FASTING-SMA-7-DAY {
    display: none;
  }

  .c3-circles-GLUCOSE-NON-FASTING-SMA-7-DAY {
    display: none;
  }

  .c3-line-GLUCOSE-FASTING-SMA-7-DAY {
    stroke-width: 4px;
  }

  .c3-line-GLUCOSE-NON-FASTING-SMA-7-DAY {
    stroke-width: 4px;
  }

  // modify 'other' circles

  .c3-circles-OTHER > circle {
    fill: none;
    stroke: currentColor;
  }

  .c3-legend-item-BEFORE-BREAKFAST-AVG > line {
    clip-path: circle(75% at 50% 50%);
  }

  .c3-legend-item-OTHER-AVG {
    transform: translateX(10px);
  }

  .c3-legend-item-OTHER-AVG > line {
    stroke-width: 1px;
  }

  .tick line {
    visibility: hidden;
  }
`;

const GlucoseChart = ({ patient, deviceIds, handleDataChange, showAverages, dateFilter, defaultDays = 30 }: ReadingChartPropsType) => {
  const navigate = useNavigate();
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [showTable, setShowTable] = useState<boolean>(true);

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.CHART_SHOW_TABLE + "_glucose").then(
      (item) => {
        if (item) setShowTable(item === "true");
      }
    );
  }, []);

  const toggleTable = () => {
    setShowTable(!showTable);

    StorageHelper.setItem(
      StorageEnum.CHART_SHOW_TABLE + "_glucose",
      "" + !showTable
    );
  };

  const { glucose } = useGetMemberDataSummary({
    patient,
    days: dateFilter,
    fetchAPIs: [DeviceTypeEnum.GLUCOSE_CATEGORY]
  });

  const serialNumbers = useMemo(() => {
    if (!glucose?.data) return;

    const array = glucose?.data.map((reading) => reading.device_id);
    return array?.filter((item, index) => array.indexOf(item) === index);
  }, [glucose?.data]);

  const patientTimezone = useMemo(() => patient?.patient?.timezone, [patient]);

  const data = useMemo(() => {
    if (glucose?.data === undefined) return;
    const dataCopy = cloneDeep(glucose?.data);
    const columns = transformGlucoseDataToColumns(
      deviceIds?.length > 0
        ? dataCopy.filter((item) => deviceIds.includes(item.device_id))
        : dataCopy,
      patientTimezone,
      false
    );
    return columns;
  }, [glucose?.data, patientTimezone, deviceIds]);

  const lastUpdatedString = glucose?.fulfilledTimeStamp
    ? "Last Updated: " +
      DateTime.fromMillis(glucose?.fulfilledTimeStamp).toFormat(
        "MM/dd/yyyy hh:mm a ZZZZ"
      )
    : undefined;

  useEffect(() => {
    if (data && !showTable) {
      const {
        columns,
        otherTickArr,
        beforeTickArr,
        xAxisParamsBefore,
        xAxisParamsOther
      } = data;

      let finalColumns = cloneDeep(columns);

      let fastingColumns = finalColumns.filter(
        (item) =>
          item?.[0]?.toLowerCase().includes("before") ||
          item?.[0] === "GLUCOSE FASTING SMA 7 DAY"
      );

      let nonFastingColumns = finalColumns.filter(
        (item) =>
          item?.[0]?.toLowerCase().includes("other") ||
          item?.[0] === "GLUCOSE NON FASTING SMA 7 DAY"
      );

      // remove averages
      if (!showAverages) {
        fastingColumns.pop();
        nonFastingColumns.pop();
      }
      // 1 is no data with just the empty array
      if (fastingColumns?.[0]?.length <= 1) {
        c3.generate({
          bindto: "#glucoseFastingChart",
          data: {
            xs: {
              "BEFORE BREAKFAST": "beforeDate",
              "BEFORE REASON": "BEFORE REASON",
              "GLUCOSE FASTING SMA 7 DAY": "beforeDate"
            },
            xFormat: "%m/%d/%Y, %H:%M:%S %p",
            columns: fastingColumns,
            types: { "BEFORE BREAKFAST": "spline" },
            colors: {
              "BEFORE BREAKFAST": "#F79009",
              "GLUCOSE FASTING SMA 7 DAY": "#1f77b4"
            },
            color: (color, dataPoint) =>
              getGlucoseColorFromDataIndex(color, dataPoint as CustomDataPoint)
          },
          axis: {
            x: {
              type: "category",
              label: {
                text: "No readings taken in selected time range",
                position: "inner-right"
              },
              tick: {
                outer: false
              }
            },
            y: {
              tick: {
                outer: false
              },
              label: "mg/DL"
            }
          },
          padding: {
            bottom: 20 //adjust chart padding bottom
          },
          legend: {
            hide: ["BEFORE BREAKFAST", "OTHER"]
          },
          tooltip: {
            contents: function (
              d: CustomDataPoint[],
              defaultTitleFormat,
              defaultValueFormat,
              color
            ) {
              // https://stackoverflow.com/a/54463412
              let $$ = this,
                config = $$.config,
                nameFormat =
                  config.tooltip_format_name ||
                  function (name) {
                    if (name === "BEFORE BREAKFAST") return "FASTING";
                    if (name === "OTHER") return "NON FASTING";
                    return name;
                  },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text,
                i,
                title,
                value,
                name,
                bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) {
                  continue;
                }

                if (!text) {
                  let titleValue = getFormattedDateTime(
                    d[i].x as DateTime,
                    patientTimezone
                  );
                  title = titleValue;
                  text =
                    "<table class='" +
                    $$.CLASS.tooltip +
                    "'>" +
                    (title || title === 0
                      ? "<tr><th>Tag</th><th>Glucose</th><th>Date</th></tr>"
                      : "");
                }

                name = nameFormat(d[i].name);
                value = valueFormat(d[i].value, d[i].name, d[i].id, d[i].index);
                bgcolor = $$.levelColor
                  ? $$.levelColor(d[i].value)
                  : color(d[i].id);
                text +=
                  "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                text +=
                  "<td class='name'><span style='background-color:" +
                  bgcolor +
                  "'></span>" +
                  name +
                  "</td>";
                text += "<td class='value'>" + value + "</td>";

                text += "<td class='value'>" + title + "</td>";

                text += "</tr>";
              }
              return text + "</table>";
            }
          }
        });
      } else if (fastingColumns?.[0]?.length > 1) {
        c3.generate({
          bindto: "#glucoseFastingChart",
          data: {
            xs: {
              "BEFORE BREAKFAST": "beforeDate",
              "BEFORE REASON": "BEFORE REASON",
              "GLUCOSE FASTING SMA 7 DAY": "beforeDate"
            },
            xFormat: "%m/%d/%Y, %H:%M:%S %p",
            columns: fastingColumns,
            types: { "BEFORE BREAKFAST": "spline" },
            colors: {
              "BEFORE BREAKFAST": "#F79009",
              "GLUCOSE FASTING SMA 7 DAY": "#1f77b4"
            },
            color: (color, dataPoint) =>
              getGlucoseColorFromDataIndex(color, dataPoint as CustomDataPoint)
          },
          axis: {
            x: {
              type: "timeseries",
              tick: {
                outer: false,
                format: "%b %d",
                values: beforeTickArr,
                fit: true,
                rotate: 0
              },
              ...xAxisParamsBefore
            },
            y: {
              tick: {
                outer: false
              },
              label: "mg/DL"
            }
          },
          padding: {
            bottom: 20 //adjust chart padding bottom
          },
          legend: {
            hide: ["BEFORE BREAKFAST", "OTHER"]
          },
          tooltip: {
            contents: function (
              d: CustomDataPoint[],
              defaultTitleFormat,
              defaultValueFormat,
              color
            ) {
              // https://stackoverflow.com/a/54463412
              let $$ = this,
                config = $$.config,
                nameFormat =
                  config.tooltip_format_name ||
                  function (name) {
                    if (name === "BEFORE BREAKFAST") return "FASTING";
                    if (name === "OTHER") return "NON FASTING";
                    return name;
                  },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text,
                i,
                title,
                value,
                name,
                bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) {
                  continue;
                }

                if (!text) {
                  let titleValue = getFormattedDateTime(
                    d[i].x as DateTime,
                    patientTimezone
                  );
                  title = titleValue;
                  text =
                    "<table class='" +
                    $$.CLASS.tooltip +
                    "'>" +
                    (title || title === 0
                      ? "<tr><th>Tag</th><th>Glucose</th><th>Date</th></tr>"
                      : "");
                }

                name = nameFormat(d[i].name);
                value = valueFormat(d[i].value, d[i].name, d[i].id, d[i].index);
                bgcolor = $$.levelColor
                  ? $$.levelColor(d[i].value)
                  : color(d[i].id);
                text +=
                  "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                text +=
                  "<td class='name'><span style='background-color:" +
                  bgcolor +
                  "'></span>" +
                  name +
                  "</td>";
                text += "<td class='value'>" + value + "</td>";

                text += "<td class='value'>" + title + "</td>";

                text += "</tr>";
              }
              return text + "</table>";
            }
          }
        });
      }

      // 1 is no data with just the empty array
      if (nonFastingColumns?.[0]?.length <= 1) {
        c3.generate({
          bindto: "#glucoseOtherChart",
          data: {
            xs: {
              OTHER: "otherDate",
              "OTHER REASON": "OTHER REASON",
              "GLUCOSE NON FASTING SMA 7 DAY": "otherDate"
            },
            xFormat: "%m/%d/%Y, %H:%M:%S %p",
            columns: nonFastingColumns,
            types: { OTHER: "spline" },
            colors: {
              OTHER: "#F79009",
              "GLUCOSE NON FASTING SMA 7 DAY": "#1f77b4"
            },
            color: (color, dataPoint) =>
              getGlucoseColorFromDataIndex(color, dataPoint as CustomDataPoint)
          },
          axis: {
            x: {
              type: "category",
              label: {
                text: "No readings taken in selected time range",
                position: "inner-right"
              },
              tick: {
                outer: false
              }
            },
            y: {
              tick: {
                outer: false
              },
              label: "mg/DL"
            }
          },
          padding: {
            bottom: 20 //adjust chart padding bottom
          },
          legend: {
            hide: ["BEFORE BREAKFAST", "OTHER"]
          }
        });
      } else if (nonFastingColumns?.[0]?.length > 1) {
        c3.generate({
          bindto: "#glucoseOtherChart",
          data: {
            xs: {
              OTHER: "otherDate",
              "OTHER REASON": "OTHER REASON",
              "GLUCOSE NON FASTING SMA 7 DAY": "otherDate"
            },
            xFormat: "%m/%d/%Y, %H:%M:%S %p",
            columns: nonFastingColumns,
            types: { OTHER: "spline" },
            colors: {
              OTHER: "#F79009",
              "GLUCOSE NON FASTING SMA 7 DAY": "#1f77b4"
            },
            color: (color, dataPoint) =>
              getGlucoseColorFromDataIndex(color, dataPoint as CustomDataPoint)
          },
          axis: {
            x: {
              type: "timeseries",
              tick: {
                outer: false,
                format: "%b %d",
                values: otherTickArr,
                fit: true,
                rotate: 0
              },
              ...xAxisParamsOther
            },
            y: {
              tick: {
                outer: false
              },
              label: "mg/DL"
            }
          },
          padding: {
            bottom: 20 //adjust chart padding bottom
          },
          legend: {
            hide: ["BEFORE BREAKFAST", "OTHER"]
          },
          tooltip: {
            contents: function (
              d: CustomDataPoint[],
              defaultTitleFormat,
              defaultValueFormat,
              color
            ) {
              // https://stackoverflow.com/a/54463412
              let $$ = this,
                config = $$.config,
                nameFormat =
                  config.tooltip_format_name ||
                  function (name) {
                    if (name === "BEFORE BREAKFAST") return "FASTING";
                    if (name === "OTHER") return "NON FASTING";
                    return name;
                  },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text,
                i,
                title,
                value,
                name,
                bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) {
                  continue;
                }

                if (!text) {
                  let titleValue = getFormattedDateTime(
                    d[i].x as DateTime,
                    patientTimezone
                  );
                  title = titleValue;
                  text =
                    "<table class='" +
                    $$.CLASS.tooltip +
                    "'>" +
                    (title || title === 0
                      ? "<tr><th>Tag</th><th>Glucose</th><th>Date</th></tr>"
                      : "");
                }

                name = nameFormat(d[i].name);
                value = valueFormat(d[i].value, d[i].name, d[i].id, d[i].index);
                bgcolor = $$.levelColor
                  ? $$.levelColor(d[i].value)
                  : color(d[i].id);
                text +=
                  "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                text +=
                  "<td class='name'><span style='background-color:" +
                  bgcolor +
                  "'></span>" +
                  name +
                  "</td>";
                text += "<td class='value'>" + value + "</td>";

                text += "<td class='value'>" + title + "</td>";

                text += "</tr>";
              }
              return text + "</table>";
            }
          }
        });
      }
    }
  }, [data, showTable, showAverages]);

  return (
    <Column gap="0px">
      <ChartControls
        readingType={DeviceTrendParam.GLUCOSE}
        handleDataChange={handleDataChange}
        defaultDays={defaultDays}
        member_id={patient?.patient?.patient_id}
        serialNumbers={serialNumbers}
      >
        <AvgTextContainer
          $visible={showAverages}
          margin="8px 0"
          textalign="right"
        >
          <>
            <>
              {data?.beforeAvg && (
                <ChartSubHeaderText $color={"#1f77b4"}>
                  Fasting Average: {data?.beforeAvg}
                </ChartSubHeaderText>
              )}
            </>
            <>
              {data?.otherAvg && (
                <ChartSubHeaderText $color={"#ff7f0e"}>
                  Not Fasting Average: {data?.otherAvg}
                </ChartSubHeaderText>
              )}
            </>
          </>
        </AvgTextContainer>
        <br/>
        <Flexbox flexDirection="row" ml={"25%"} mr={"10px"}>
          <ThreeLinesContainer
            $showTable={showTable}
            aria-label="tableIcon"
            onClick={toggleTable}
          >
            <ThreeLinesSVG $showTable={showTable} />
          </ThreeLinesContainer>
          <ChartIconContainer
            $showTable={showTable}
            aria-label="gridIcon"
            onClick={toggleTable}
          >
            <ChartIconSVG $showTable={showTable} />
          </ChartIconContainer>
        </Flexbox>
      </ChartControls>
      <GlucoseChartContainer style={{marginTop: `${showTable ? "12px" : "50px"}`}}>
        {glucose?.fetching && <LoadingFallback delay={50} />}
        {glucose?.data ? (
          showTable ? (
            <GlucoseTable
              glucoseData={
                deviceIds?.length > 0
                  ? glucose.data.filter((item) =>
                      deviceIds.includes(item.device_id)
                    )
                  : glucose.data
              }
              patientTimezone={patientTimezone}
            />
          ) : (
            <ChartContainer>
              <ChartHeader>
                <ChartHeaderText>Fasting</ChartHeaderText>
              </ChartHeader>
              <div
                id="glucoseFastingChart"
                className="glucoseChart"
                data-testid="glucoseFastingChart"
              />
              <ChartHeader>
                <ChartHeaderText>Not Fasting</ChartHeaderText>
              </ChartHeader>
              <div
                id="glucoseOtherChart"
                className="glucoseChart"
                data-testid="glucoseOtherChart"
              />
            </ChartContainer>
          )
        ) : (
          !glucose?.fetching &&
          glucose?.error && (
            <div style={{ margin: "0 36px" }}>
              <ErrorComponent error={glucose?.error} />
            </div>
          )
        )}
      </GlucoseChartContainer>
      <Flexbox justifyContent="space-between">
        <Typography variant="body1">{lastUpdatedString}</Typography>
        {canDownloadChart(currentRole) && (
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/reports/${ReportEnum.MEMBER_CHART_SHARE}/memberId/${patient.patient.patient_id}`
              )
            }
            startIcon={<FileDownloadOutlined />}
          >
            Download
          </Button>
        )}
      </Flexbox>
    </Column>
  );
};

export default GlucoseChart;
