import { Suspense, lazy } from "react";
import AllTeams from "../pages/Teams/AllTeams";
import PatientList from "../pages/AllUserList/PatientList";
import StaffList from "../pages/AllUserList/StaffList";
import CreateUserForm from "../pages/CreateUserForm/CreateUserForm";
import Help from "../pages/Help";
import MigratePatient from "../pages/MigratePatient/MigratePatient";
import { Profile } from "../pages/Profile";
import MyTeam from "../pages/Teams/MyTeam";
import SelectedTeam from "../pages/Teams/SelectedTeam";
import CarerPatientList from "../pages/CarerPatientList/CarerPatientList";
import RecentOrdersTable from "../pages/RecentOrders/RecentOrdersTable";
import RouteComponentType from "../types/RouteComponentType";
import CreateTeam from "../pages/Teams/CreateTeam";
import CustomSearchContainer from "../components/CustomSearchTables/CustomSearchContainer";
import MyIntakes from "../pages/Intakes/Intakes";
import ReportListPage from "../pages/Reports/ReportListPage";
import ReportPage from "../pages/Reports/ReportPage";
import ReportedCancellations from "../pages/ReportedCancellations/ReportedCancellations";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import InactivePatientList from "../pages/AllUserList/InactivePatientList";
import Tasks from "../pages/Tasks/Tasks";
import RouteComponentTypeEnum from "common/enums/RouteComponentTypeEnum";
import PatientActivityList from "../pages/AllUserList/PatientActivityList";
import MemberDetailsReadings from "../pages/MemberDetails/Readings/MemberDetailsReadings";
import MemberDetailsOrders from "../pages/MemberDetails/MemberDetailsOrders";
import MemberDetailsEncounters from "../pages/MemberDetails/MemberDetailsEncounters";
import PatientDetailsRedirect from "../pages/MemberDetails/PatientDetailsRedirect";
import MemberDetailsProfile from "../pages/MemberDetails/Profile/MemberDetailsProfile";
import MemberDetailsGoals from "../pages/MemberDetails/MemberDetailsGoals";
import MemberCancellationRedirect from "../pages/MemberDetails/MemberCancellationRedirect";
import CreateUpdateAppointment from "../pages/MemberDetails/Appointments/CreateUpdateAppointment";
import MemberDetailsAppointments from "../pages/MemberDetails/Appointments/MemberDetailsAppointments";
import StaffDetails from "../pages/AllUserList/StaffDetails/StaffDetails";
import MemberDetailsRedirect from "../pages/MemberDetails/MemberDetailsRedirect";
import ReportedCancellationsByMember from "../pages/ReportedCancellations/ReportedCancellationsByMember";
import MemberDetailsStartIntake from "../pages/MemberDetails/StartIntake/MemberDetailsStartIntake";
import VisitFlow from "../pages/Visits/VisitFlow";
import NewNurseSchedules from "../pages/AllUserList/NursesSchedules/NewNursesSchedules";
import SameDayTimeOff from "../pages/AllUserList/NursesSchedules/SameDayTimeOff";
import ManualReassignAppointment from "../pages/MemberDetails/Appointments/ManualReassignAppointment";
import TimeOff from "../pages/AllUserList/NursesSchedules/TimeOff";
import MemberDetailsOverview from "../pages/MemberDetails/Overview/MemberDetailsOverview";
import NewScheduleToDoContainer from "../components/ScheduleToDoContainer/NewScheduleToDoContainer";
import ArcOfDayDashboard from "../pages/ArcOfDay/ArcOfDayDashboard";
import ArcOfDayDetails from "../pages/ArcOfDay/ArcOfDayDetails";
import LogoutPage from "../pages/LogoutPage";

const OrderDevices = lazy(() => import("../pages/OrderDevices/OrderDevices"));
const OrderDevicesDetails = lazy(
  () => import("../pages/OrderDevices/OrderDevicesDetails")
);
const RegisterPatient = lazy(
  () => import("../pages/RegisterPatient/RegisterPatient")
);
const RequestConsents = lazy(
  () => import("../pages/RequestConsents/RequestConsents")
);
const AllOrdersTable = lazy(
  () => import("../pages/OrderDevices/AllOrdersTable")
);

interface IProps {
  component: RouteComponentType<any>;
}

const ComponentLoader = ({
  component
}: {
  component: RouteComponentType<any>;
}) => {
  const comp = ComponentLoaderSwitch({ component });
  return <Suspense fallback={<LoadingFallback />}>{comp}</Suspense>;
};

const ComponentLoaderSwitch = ({ component }: IProps) => {
  const { type, componentParams } = component;
  switch (type) {
    case RouteComponentTypeEnum.LOGOUT:
      return <LogoutPage {...componentParams} />;
    case RouteComponentTypeEnum.CREATE_USER:
      return <CreateUserForm {...componentParams} />;
    case RouteComponentTypeEnum.CARER_PATIENT_LIST:
      return <CarerPatientList {...componentParams} />;
    case RouteComponentTypeEnum.PATIENT_LIST:
      return <PatientList {...componentParams} />;
    case RouteComponentTypeEnum.INACTIVE_PATIENT_LIST:
      return <InactivePatientList {...componentParams} />;
    case RouteComponentTypeEnum.PATIENT_ACTIVITY_LIST:
      return <PatientActivityList {...componentParams} />;
    case RouteComponentTypeEnum.STAFF_LIST:
      return <StaffList {...componentParams} />;
    case RouteComponentTypeEnum.STAFF_DETAILS:
      return <StaffDetails {...componentParams} />;
    case RouteComponentTypeEnum.NEW_NURSES_SCHEDULES:
      return <NewNurseSchedules {...componentParams} />;
    case RouteComponentTypeEnum.SAME_DAY_TIME_OFF:
      return <SameDayTimeOff {...componentParams} />;
    case RouteComponentTypeEnum.TIME_OFF:
      return <TimeOff {...componentParams} />;
    case RouteComponentTypeEnum.CUSTOM_SEARCH_TABLE:
      return <CustomSearchContainer {...componentParams} />;
    case RouteComponentTypeEnum.INTAKES:
      return <MyIntakes {...componentParams} />;
    case RouteComponentTypeEnum.PROFILE:
      return <Profile {...componentParams} />;
    case RouteComponentTypeEnum.REGISTER_MEMBER:
      return <RegisterPatient {...componentParams} />;
    case RouteComponentTypeEnum.REQUEST_CONSENTS:
      return <RequestConsents {...componentParams} />;
    case RouteComponentTypeEnum.MIGRATE_PATIENT:
      return <MigratePatient {...componentParams} />;
    case RouteComponentTypeEnum.ORDER_DEVICES:
      return <OrderDevices {...componentParams} />;
    case RouteComponentTypeEnum.ORDER_DEVICES_DETAILS:
      return <OrderDevicesDetails {...componentParams} />;
    case RouteComponentTypeEnum.ALL_RECENT_ORDERS:
      return <AllOrdersTable {...componentParams} />;
    case RouteComponentTypeEnum.RECENT_ORDERS:
      return <RecentOrdersTable {...componentParams} />;
    case RouteComponentTypeEnum.HELP:
      return <Help />;
    case RouteComponentTypeEnum.ALL_TEAMS:
      return <AllTeams {...componentParams} />;
    case RouteComponentTypeEnum.MY_TEAM:
      return <MyTeam {...componentParams} />;
    case RouteComponentTypeEnum.SELECTED_TEAM:
      return <SelectedTeam {...componentParams} />;
    case RouteComponentTypeEnum.CREATE_TEAM:
      return <CreateTeam {...componentParams} />;
    case RouteComponentTypeEnum.REPORT_LIST:
      return <ReportListPage {...componentParams} />;
    case RouteComponentTypeEnum.REPORTS:
      return <ReportPage {...componentParams} />;
    case RouteComponentTypeEnum.REPORTED_CANCELLATIONS:
      return <ReportedCancellations {...componentParams} />;
    case RouteComponentTypeEnum.REPORTED_CANCELLATIONS_BY_MEMBER:
      return <ReportedCancellationsByMember {...componentParams} />;
    case RouteComponentTypeEnum.TASKS:
      return <Tasks {...componentParams} />;
    case RouteComponentTypeEnum.NEW_SCHEDULE_TODO_CONTAINER:
      return <NewScheduleToDoContainer {...componentParams} />;
    // v2 member details components
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_OVERVIEW:
      return <MemberDetailsOverview {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_READINGS:
      return <MemberDetailsReadings {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_ENCOUNTERS:
      return <MemberDetailsEncounters {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_ORDERS:
      return <MemberDetailsOrders {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_PROFILE:
      return <MemberDetailsProfile {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_APPOINTMENTS:
      return <MemberDetailsAppointments {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_DETAILS_GOALS:
      return <MemberDetailsGoals {...componentParams} />;
    case RouteComponentTypeEnum.MEMBER_DETAILS_START_INTAKE:
      return <MemberDetailsStartIntake {...componentParams} />;
    case RouteComponentTypeEnum.NEW_MEMBER_APPOINTMENT:
      return <CreateUpdateAppointment {...componentParams} />;
    case RouteComponentTypeEnum.EDIT_MEMBER_APPOINTMENT:
      return <CreateUpdateAppointment {...componentParams} />;
    case RouteComponentTypeEnum.MANUAL_REASSIGN_APPOINTMENT:
      return <ManualReassignAppointment {...componentParams} />;
    case RouteComponentTypeEnum.PATIENT_DETAILS_REDIRECT:
      return <PatientDetailsRedirect {...componentParams} />;
    case RouteComponentTypeEnum.MEMBER_DETAILS_REDIRECT:
      return <MemberDetailsRedirect {...componentParams} />;
    case RouteComponentTypeEnum.PATIENT_CANCELLATION_REDIRECT:
      return <MemberCancellationRedirect {...componentParams} />;
    case RouteComponentTypeEnum.VISITS:
      return <VisitFlow {...componentParams} />;
    case RouteComponentTypeEnum.ARC_OF_DAY_DASHBOARD:
      return <ArcOfDayDashboard {...componentParams} />;
    case RouteComponentTypeEnum.ARC_OF_DAY_DETAILS:
      return <ArcOfDayDetails {...componentParams} />;
    default:
      return <></>;
  }
};
export default ComponentLoader;
