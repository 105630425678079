import { WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { RootState } from "common/redux";
import FeatureFlags from "common/config/FeatureFlags";
import StartEncounterButton from "../../pages/MemberDetails/Header/StartEncounterButton";
import MemberType from "common/types/MemberType";
import { useSelector } from "react-redux";
import Tasks from "../../pages/Tasks/Tasks";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import { Flexbox } from "../../styling/NewStyleComponents";
import TodaysAppointments from "../../pages/MemberDetails/Appointments/TodaysAppointments";
import useGetCalendarVisits, {
  filterTodaysUpcomingAppointment
} from "../../hooks/data_loaders/useGetCalendarVisits";
import useSanitizedParams from "../../hooks/useSanitizedParams";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  member: MemberType;
}

const StartVisitModal = ({ isOpen, onRequestClose, member }: IProps) => {
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const { memberId } = useSanitizedParams();
  const { data: calendarVisitsData } = useGetCalendarVisits({
    patient_id: memberId,
    startdate: DateTime.now().startOf("day"),
    enddate: DateTime.now().endOf("day"),
    currentRole
  });

  // find today's appointments
  const todaysUpcomingNurseAppointmentsData = useMemo(
    () => filterTodaysUpcomingAppointment({ data: calendarVisitsData }),
    [calendarVisitsData]
  );

  // TODO - we may just remove this in the future
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Start Encounter Modal"
      modalHeight="70vh"
      modalWidth="800px"
    >
      <ModalHeader onRequestClose={onRequestClose}>Start Encounter</ModalHeader>
      <ModalBody>
        {FeatureFlags(user?.user_id).CARE_FLOWS && (
          <Flexbox gap={"10px"} flexDirection={"column"}>
            <TodaysAppointments
              upcomingAppointments={todaysUpcomingNurseAppointmentsData}
              memberId={memberId}
            />
            <Tasks
              tasksStatuses={[TaskStatusEnum.TODO]}
              componentHeader="Urgent Alerts"
              showWhenNoTasks={false}
              showTaskCount={false}
              noTasksMessage=""
              tableHeight="240px"
              tooltipContent=""
              outerContainerMargin="0px"
            />
          </Flexbox>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          {!FeatureFlags(user?.user_id).CARE_FLOWS && (
            <StartEncounterButton patient={member} />
          )}
          <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default StartVisitModal;
