import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { isFalsy } from "common/helpers/helpers";

const VisitFieldMemberReadingsReview = () => {
  const { answers } = useSelector((state: RootState) => state.visits);

  const readingTrends = useMemo(() => {
    let res = [];
    if (!isFalsy(answers[CareFlowFieldIdsEnum.PRECALL_GLUCOSE_7D_AVG]))
      res.push(answers[CareFlowFieldIdsEnum.PRECALL_GLUCOSE_7D_AVG]);
    if (!isFalsy(answers[CareFlowFieldIdsEnum.PRECALL_GLUCOSE_30D_AVG]))
      res.push(answers[CareFlowFieldIdsEnum.PRECALL_GLUCOSE_30D_AVG]);
    if (!isFalsy(answers[CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_7D_AVG]))
      res.push(answers[CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_7D_AVG]);
    if (!isFalsy(answers[CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_30D_AVG]))
      res.push(answers[CareFlowFieldIdsEnum.PRECALL_BLOOD_PRESSURE_30D_AVG]);

    return res;
  }, [answers]);

  return (
    <>
      {readingTrends?.map((item) => {
        return (
          <Typography key={item} mb={"5px"}>
            {item}
          </Typography>
        );
      })}
    </>
  );
};

export default VisitFieldMemberReadingsReview;
