import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  MEMBER_CHART,
  ORDER_DETAILS,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  MEMBER_CHART_CALENDARING,
  LOGOUT,
  VISITS,
  STAFF_DETAILS,
  PROVIDER_SCHEDULES_SIDEBAR,
  PROVIDER_SCHEDULES,
  NURSE_SCHEDULES
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT
} from "../RouteSidebar";
import FeatureFlags from "common/config/FeatureFlags";
import { PersonIcon } from "../../assets/images/icons";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_ORDERS]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "Nurse Schedules",
          itemLink: "/nurse-schedules",
          ItemIcon: PersonIcon
        }
      ]
    }
  ];

  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [PROVIDER_SCHEDULES_SIDEBAR]
    });
  }

  sidebarItems.push(SIDEBAR_REPORTS, SIDEBAR_SUPPORT);

  return sidebarItems;
};

const MEMBER_CARE_SPECIALIST = {
  label: "Member Care Specialist",
  routes: [
    {
      path: "/",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/members",
      components: [ALL_MEMBERS({})]
    },
    {
      path: "/orders",
      components: [
        {
          type: "order-devices"
        },
        ALL_RECENT_ORDERS()
      ]
    },
    ...MEMBER_CHART_CALENDARING(),
    ORDER_DETAILS(),
    ...MEMBER_CHART(RolesEnum.MEMBER_CARE_SPECIALIST),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    STAFF_DETAILS,
    {
      path: "/help",
      components: [
        {
          type: "help"
        }
      ]
    },
    {
      path: "/my-profile",
      components: [
        {
          type: "profile"
        }
      ]
    },
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    NURSE_SCHEDULES,
    LOGOUT,
    VISITS
  ],
  sidebar: sidebar()
};

export default MEMBER_CARE_SPECIALIST;
